import { Injectable } from '@angular/core';

import { IGoodsIssueFormItem, IGoodsIssueItem } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPGoodIssueFactory {
  toRequest(goodsIssue: IGoodsIssueFormItem): IGoodsIssueItem {
    return {
      ...goodsIssue.goodsIssueInfo,
      id: goodsIssue.id,
      rowVersion: goodsIssue.rowVersion,
      lines: goodsIssue.lines
    };
  }

  fromResponse(goodsIssueResponse: IGoodsIssueItem): IGoodsIssueFormItem {
    const { id, rowVersion, lines, ...goodsIssueInfo } = goodsIssueResponse;

    return {
      id,
      rowVersion,
      goodsIssueInfo: {
        ...goodsIssueInfo,
        status: {
          id: goodsIssueInfo.statusId,
          value: goodsIssueInfo.statusName
        }
      },
      lines
    };
  }
}
