import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { Observable, of } from 'rxjs';
import { IInventoryStatus, InventoryStatusCondition, InventoryStatusType } from '../interfaces';
import { Query, TableQueryBuilder } from '../builders';

export const INVENTORY_STATUS_CLASSIFICATIONS: InventoryStatusCondition[] = [
  { id: 1, value: 'PRIME' },
  { id: 2, value: 'REJECT' }
];

export const INVENTORY_STATUS_TYPES: (InventoryStatusType & { disabled?: boolean })[] = [
  { id: 1, value: 'Active' },
  { id: 2, value: 'Inactive' },
  { id: 4, value: 'System' }
];

@Injectable({
  providedIn: 'root'
})
export class InventoryStatusService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('inventoryStatuses');
  }

  get inventoryStatusClassifications(): Observable<InventoryStatusCondition[]> {
    return of(INVENTORY_STATUS_CLASSIFICATIONS);
  }

  get inventoryStatusTypes(): Observable<(InventoryStatusType & { disabled?: boolean })[]> {
    return of(INVENTORY_STATUS_TYPES);
  }

  get inventoryStatuses(): Observable<IInventoryStatus[]> {
    return this.get<IInventoryStatus[]>();
  }

  getList(query?: Partial<Query<IInventoryStatus>>): Observable<ICollectionResponse<IInventoryStatus>> {
    const params: any = {};
    if (query) {
      params.query = new TableQueryBuilder(query).build().serialize();
    }
    return this.get<ICollectionResponse<IInventoryStatus>>(params, 'list');
  }

  createInventoryStatus(inventoryStatus: Omit<IInventoryStatus, 'id'>) {
    return this.post<Omit<IInventoryStatus, 'id'>>(inventoryStatus, {}, '');
  }

  update(inventoryStatus: IInventoryStatus) {
    return this.put(inventoryStatus, undefined, inventoryStatus.id);
  }

  partialUpdate(inventoryStatus: Partial<IInventoryStatus> & { id: number }) {
    return this.patch(inventoryStatus, undefined, inventoryStatus.id);
  }

  remove(inventoryStatusId: number) {
    return this.delete(undefined, inventoryStatusId);
  }
}
