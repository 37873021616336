import { NgModule } from '@angular/core';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

@NgModule({
  declarations: [ActivityLogComponent],
  imports: [CommonModule, MatCardModule],
  exports: [ActivityLogComponent]
})
export class ActivityLogModule {}
