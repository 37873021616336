import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AdvancedSearchComponent } from './advanced-search.component';
import { ERPAutocompleteModule } from '../autocomplete';
import { ERPIconModule } from '../icon';
import { ERPAdvancedSearchComponentService } from './advanced-search-component.service';
import { ERPTooltipModule } from '../tooltip';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ERPAutocompleteModule, ERPIconModule, ERPTooltipModule],
  declarations: [AdvancedSearchComponent],
  providers: [ERPAdvancedSearchComponentService],
  exports: [AdvancedSearchComponent]
})
export class AdvancedSearchModule {}
