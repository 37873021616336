import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import { IAssociatedMaterialRelease, ReadyForGoodsIssueResponse } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ERPReleaseService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('releases');
  }

  getCustomerReleases(params: object) {
    return this.get<string[]>(params, 'releaseNumbers');
  }

  getReadyForGoodsIssue(materialReleaseId: number) {
    return this.get<ReadyForGoodsIssueResponse[]>({ materialReleaseId }, 'readyForGoodsIssue');
  }

  getAssociatedMaterialReleases(
    soId: number,
    query?: any
  ): Observable<ICollectionResponse<IAssociatedMaterialRelease>> {
    const params = !!query ? { query } : {};
    return this.get<ICollectionResponse<IAssociatedMaterialRelease>>(params, `${soId}/reference`);
  }
}
