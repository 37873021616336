import { ReplaySubject, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr/src/IHttpConnectionOptions';

import { ISignalRMessage } from '../interfaces';

import { ERPAuthService } from './auth.service';
import { ERPApiConfigService } from '../../../../core/src/lib/services/api-config.service';

@Injectable({
  providedIn: 'root'
})
export class ERPSignalRConnectionService {
  readonly messages$ = new ReplaySubject<ISignalRMessage>();
  connection: signalR.HubConnection;
  constructor(
    private readonly apiConfigService: ERPApiConfigService,
    private readonly authService: ERPAuthService
  ) {}

  initConnection() {
    this.authService
      .getToken(false)
      .pipe(
        catchError(error => {
          // eslint-disable-next-line no-console
          console.error('MSAL get token error:', error);
          this.authService.login();

          return throwError(error);
        }),
        first()
      )
      .subscribe((token: string) => {
        // eslint-disable-next-line no-console
        console.log('token success');
        this.initSooket(token);
      });
  }

  initSooket(token: string) {
    const options = {
      accessTokenFactory: () => token,
      headers: { Authorization: `bearer ${token}` }
    } as IHttpConnectionOptions;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.apiConfigService.domain + '/gateway/systemEvents', options)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();

    this.connection.on('messageBus', (message: string) => {
      const parsedMessage = JSON.parse(message) as ISignalRMessage;
      this.messages$.next(parsedMessage);
    });

    this.connection.start().catch(err => {
      // eslint-disable-next-line no-console
      console.error('SignalR connection error:', err);
      if (err.message.includes('401')) {
        // try reconnect with Refresh token, otherwise login again
        this.authService
          .updateToken()
          .then(isTokenRefreshed => {
            if (isTokenRefreshed) {
              // eslint-disable-next-line no-console
              console.info(`Token refreshed at ${new Date()}`);
            } else {
              this.authService.login();
            }
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error('SignalR refresh token error:', error);
            this.authService.login();
          });
      }
    });
  }

  disconnect(): Promise<void> {
    return this.connection.stop();
  }
}
