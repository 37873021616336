import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts/http/base-http.service';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ERPSharedCompaniesService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('companies');
  }

  getCompanies(params: object) {
    return this.get<ICollectionResponse<any>>(params);
  }
}
