<mat-radio-group
  color="primary"
  [formControl]="control"
  (change)="changed.emit($event)"
  (keydown.enter)="onEnterKeyDown($event)"
  [@.disabled]="true"
>
  <mat-radio-button
    class="margin-bottom-3 margin-right-3"
    *ngFor="let option of options; trackBy: trackByFn"
    [value]="valueFn(option)"
    [disabled]="option.disabled"
  >
    {{ labelFn(option) }}
  </mat-radio-button>
</mat-radio-group>
