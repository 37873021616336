import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';
import { ICollectionResponse } from '../abstracts/http/collection-response.interface';
import { IUser } from '../interfaces';
import { TableQueryBuilder } from '../builders';

@Injectable({
  providedIn: 'root'
})
export class ERPUsersService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('users');
  }

  get users(): Observable<IUser[]> {
    return this.get<IUser[]>({});
  }

  getUsersList(params: object): Observable<ICollectionResponse<IUser>> {
    return this.get<ICollectionResponse<IUser>>(params, '/filtered');
  }

  getUsers(params: object) {
    return this.get<IUser[]>(params, 'search');
  }
}
