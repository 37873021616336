export enum RevisionsMode {
  PlainText = 1,
  ChangeScript = 2
}

export interface IRevisionRecord {
  title: string;
  documentId: number;
  dateTime: string;
  authorId: string;
  authorName: string;
  messageText:
    | {
        propertyName: string;
        line: string;
        oldValue: string;
        newValue: string;
      }[]
    | string;
  workflowStatusId: number;
  messageTypeId: RevisionsMode;
  revision: number;
}

export interface IStyledRevisionRecord extends IRevisionRecord {
  style: string;
  order: number;
}

export type RevisionRecord<T = unknown> = IRevisionRecord & T;
export type StyledRevisionRecord<T = unknown> = IStyledRevisionRecord & T;
export type ActivityLogs<T = unknown> = StyledRevisionRecord<T>[][];
