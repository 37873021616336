import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { CommonModule } from '@angular/common';

export type BannerType = 'error' | 'success' | 'warning' | 'info' | null;
export type BannerAction = {
  type: 'flat' | 'link' | 'icon';
  label: string;
  action: () => void;
};

@Component({
  standalone: true,
  selector: 'erp-banner',
  template: `
    <div class="erp-banner animated faster">
      <erp-button
        *ngIf="closeable"
        color="none"
        variant="icon"
        class="flex erp-banner__close"
        [ngClass]="[showTitle ? '' : 'without-title']"
      >
        <erp-icon (click)="onRemove()" icon="action:cross"> </erp-icon>
      </erp-button>
      <div
        [ngClass]="{
          'erp-banner-content__error': type === 'error',
          'erp-banner-content__success': type === 'success',
          'erp-banner-content__warning': type === 'warning',
          'erp-banner-content__info': type === 'info'
        }"
        class="erp-banner-content flex"
      >
        <erp-icon
          [ngClass]="{
            'erp-banner-content__error-icon': type === 'error',
            'erp-banner-content__success-icon': type === 'success',
            'erp-banner-content__warning-icon': type === 'warning',
            'erp-banner-content__info-icon': type === 'info'
          }"
          class="erp-banner-content__icon erp-banner-content__error-icon"
          icon="action:notification"
          size="fit"
        >
        </erp-icon>
        <div class="flex-grow-1">
          <div *ngIf="showTitle" class="erp-banner-content__title">
            <ng-container *ngIf="title">
              {{ title }}
            </ng-container>
            <ng-container *ngIf="!title && type === 'error'" i18n="@@common.banner.title.error"> Error </ng-container>
            <ng-container *ngIf="!title && type === 'success'" i18n="@@common.banner.title.success">
              Success
            </ng-container>
            <ng-container *ngIf="!title && type === 'warning'" i18n="@@common.banner.title.warning">
              Warning
            </ng-container>
            <ng-container *ngIf="!title && type === 'info'" i18n="@@common.banner.title.info"> Info </ng-container>
          </div>
          <div class="erp-banner-message">
            <ng-container *ngIf="isMessage">
              <div>
                {{ message }}
              </div>
            </ng-container>
          </div>

          <div *ngIf="actions" class="erp-banner__actions">
            <erp-button
              *ngFor="let action of actions"
              [variant]="action.type"
              [ngClass]="{
                'erp-banner__actions--error': type === 'error',
                'erp-banner__actions--success': type === 'success',
                'erp-banner__actions--warning': type === 'warning',
                'erp-banner__actions--info': type === 'info'
              }"
              (click)="action.action()"
            >
              {{ action.label }}
            </erp-button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./banner.component.scss'],
  imports: [ERPButtonModule, ERPIconModule, CommonModule]
})
export class ERPBannerComponent {
  @Input() showTitle = true;
  @Input() type: BannerType = null;
  @Input() title: string = '';
  @Input() closeable: boolean = true;
  @Input() message: string = '';
  @Input() actions: BannerAction[] | null;
  @Output() readonly remove = new EventEmitter<unknown>();

  get isMessage() {
    return typeof this.message === 'string';
  }

  onRemove() {
    this.remove.emit();
    this.remove.complete();
  }
}
