import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Renderer2,
  Self
} from '@angular/core';
import {
  LegacyTooltipPosition as TooltipPosition,
  MatLegacyTooltip as MatTooltip
} from '@angular/material/legacy-tooltip';

@Directive({
  selector: '[erpTooltip]',
  providers: [MatTooltip]
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @Input('erpTooltip')
  readonly message: string;
  @Input('erpTooltipPosition')
  readonly position: TooltipPosition = 'above';
  @Input('erpTooltipDisabled')
  readonly disabled = false;
  @Input() readonly erpTooltipShowDelay = 0;
  @Input() readonly erpTooltipHideDelay = 0;
  @Input() readonly erpTooltipForTruncatedText = false;

  hiddenText: HTMLElement;

  constructor(
    @Self() readonly tooltip: MatTooltip,
    @Inject(DOCUMENT) private $document: Document,
    private readonly $ref: ElementRef,
    private readonly $renderer: Renderer2
  ) {
    this.tooltip = tooltip;
    this.tooltip.tooltipClass = 'erp-tooltip';
    this.hiddenText = $document.createElement('span');
    this.hiddenText.setAttribute('style', 'visibility: hidden; text-wrap: nowrap; position: fixed; top: -99999999px;');
    this.$renderer.appendChild(this.$document.body, this.hiddenText);
  }

  @HostListener('mouseover')
  onMouseOver() {
    if (!this.chackTrucatedCondition()) return;

    this.tooltip.message = this.message;
    this.tooltip.position = this.position;
    this.tooltip.disabled = this.disabled;
    this.tooltip.show(this.erpTooltipShowDelay);
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.tooltip.hide(this.erpTooltipHideDelay);
  }

  ngAfterViewInit(): void {
    const text = this.$ref.nativeElement.textContent;
    const target = this.$ref.nativeElement.querySelector('mat-label') || this.$ref.nativeElement;
    const fontSize = parseFloat(window.getComputedStyle(target, null).getPropertyValue('font-size'));
    if (!Number.isNaN(fontSize)) {
      const updatedStyle = `font-size: ${fontSize}px; ${this.hiddenText.getAttribute('style')}`;
      this.hiddenText.setAttribute('style', updatedStyle);
    }

    this.hiddenText.innerHTML = text;
  }

  ngOnDestroy(): void {
    this.$renderer.removeChild(this.$document.body, this.hiddenText);
  }

  chackTrucatedCondition() {
    if (!this.erpTooltipForTruncatedText) return true;

    const fullWidth = this.hiddenText.offsetWidth;
    const target = this.$ref.nativeElement.querySelector('mat-label') || this.$ref.nativeElement;
    const actualWidth = target?.offsetWidth || 0;

    if (fullWidth > actualWidth) {
      return true;
    } else {
      return false;
    }
  }
}
