import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { NgControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';

import {
  AutoCleanupFeature,
  BaseControlComponent,
  ComposedValidators,
  CustomValidators,
  ERPFormStateDispatcher,
  ERPNumberUtil,
  Features
} from '@erp/shared';

export type InputType = 'integer' | 'decimal' | 'amount';

export const DEFAULT_AMOUNT_DECIMALS = 2;
export const DEFAULT_DECIMALS = 6;

export const MIN_SAFE_AMOUNT = CustomValidators.safeAmount.MIN_SAFE_VALUE;
export const MAX_SAFE_AMOUNT = CustomValidators.safeAmount.MAX_SAFE_VALUE;
export const MIN_SAFE_DECIMAL = CustomValidators.safeDecimal.MIN_SAFE_VALUE;
export const MAX_SAFE_DECIMAL = CustomValidators.safeDecimal.MAX_SAFE_VALUE;
export const MIN_SAFE_INTEGER = CustomValidators.safeInteger.MIN_SAFE_VALUE;
export const MAX_SAFE_INTEGER = CustomValidators.safeInteger.MAX_SAFE_VALUE;

@Component({
  selector: 'erp-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPNumberComponent<T extends number, R extends string>
  extends BaseControlComponent<T, R>
  implements OnInit
{
  readonly destroyed$: Observable<unknown>;
  private _placeholder: string | null;
  @Input() set placeholder(input: string) {
    this._placeholder = input;
  }
  get placeholder() {
    const value = this.control.value?.toString() as R | number;

    if (this.format && !this._placeholder) {
      const fixedDigits = this.format.split('.')[1]?.length ?? 0;

      const formattedZero = Number(0).toFixed(fixedDigits);
      return formattedZero;
    } else {
      return this._placeholder as string;
    }
  }
  @ViewChild('input') input: MatInput;
  @Input() readonly readonly: boolean;
  @Input() readonly options: number[];
  @Input() readonly align: string;
  @Input() readonly isFocused: boolean;
  @Input() set format(format: string) {
    this._format = format;
    this.updateToFormat();
  }
  get format() {
    return this._format;
  }

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly keyDownEnter = new EventEmitter();

  private _type: InputType;
  private _format: string;

  @Input() set type(type: InputType) {
    this._type = type;
    this.setValidators();
  }

  get type() {
    return this._type;
  }

  readonly control = new UntypedFormControl(null);

  readonly modelToViewFormatter = (value: T | R | null) => {
    if (value === null || value === '') {
      return null;
    }

    if (+value === +this.control.value) {
      return this.formatViewValue(this.control.value ?? value) as R;
    }

    return this.formatViewValue(value) as R;
  };

  readonly viewToModelParser = (value: T | R | null) => {
    if (value === null || value === '') {
      return null;
    }

    if (Number.isFinite(Number(value))) {
      return +value as T;
    }

    return value as T;
  };

  writeValue(value: T | null): void {
    this.control.setValue(this.modelToViewFormatter(value), { emitEvent: false });
    this.updateToFormat();
  }

  constructor(
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  get defaultValidator() {
    switch (this.type) {
      case 'integer':
        return ComposedValidators.integer;
      case 'decimal':
        return ComposedValidators.decimal;
      case 'amount':
        return ComposedValidators.amount;
    }
  }

  updateToFormat() {
    const outerValue = this.ctrl.control?.value;
    const value = outerValue || (this.control.value?.toString() as R | number);

    if (!this.format || value === undefined) {
      return;
    }

    const fixedDigits = this.format.split('.')[1]?.length ?? 0;

    this.control.setValue(Number(value).toFixed(fixedDigits) ?? null, { emitEvent: false });
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.setValidators();

    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.changed.emit(this.viewToModelParser(value));
    });

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.ctrl.control?.updateValueAndValidity();
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  private setValidators() {
    const asyncValidators = this.ctrl.control?.asyncValidator ?? null;

    this.ctrl.control?.addValidators(this.defaultValidator);

    if (asyncValidators) {
      this.ctrl.control?.addAsyncValidators(asyncValidators);
    }

    this.onValidatorChange?.();
  }

  onKeyPress(event: KeyboardEvent) {
    const { key: char } = event;
    const { value, selectionStart, selectionEnd } = event.target as HTMLInputElement;
    const start = 0;
    const prefixValue = value.slice(start, selectionStart as number);
    const suffixValue = value.slice(selectionEnd as number, value.length);
    const possibleValue = (prefixValue + char + suffixValue) as R;

    if (!this.isValidInput(possibleValue)) {
      event.preventDefault();
    }
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
    this.updateToFormat();
    this.keyDownEnter.emit();
  }

  private isValidInput(value: R | null) {
    switch (this.type) {
      case 'integer':
        return ERPNumberUtil.isValidInteger(value);
      case 'decimal':
        return ERPNumberUtil.isValidDecimal(value);
      case 'amount':
        return ERPNumberUtil.isValidAmount(value);
    }
  }

  private isInAmountBoundary(value: T) {
    return value >= MIN_SAFE_AMOUNT && value <= MAX_SAFE_AMOUNT;
  }

  private isInDecimalBoundary(value: T) {
    return value >= MIN_SAFE_DECIMAL && value <= MAX_SAFE_DECIMAL;
  }

  private isInIntegerBoundary(value: T) {
    return value >= MIN_SAFE_INTEGER && value <= MAX_SAFE_INTEGER;
  }

  private formatViewValue(value: T | R | null) {
    const valueAsNumber = Number(value) as T;
    const valueIsNumber = typeof value === 'number';

    switch (this.type) {
      case 'amount':
        const isInAmountBoundary = this.isInAmountBoundary(valueAsNumber);

        return isInAmountBoundary
          ? this.roundTo(value, DEFAULT_AMOUNT_DECIMALS).toFixed(DEFAULT_AMOUNT_DECIMALS)
          : value;
      case 'decimal':
        const isInDecimalBoundary = this.isInDecimalBoundary(valueAsNumber);

        return isInDecimalBoundary && valueIsNumber ? this.roundTo(value, DEFAULT_DECIMALS) : value;
      case 'integer':
        const isInIntegerBoundary = this.isInIntegerBoundary(valueAsNumber);

        return isInIntegerBoundary && valueIsNumber ? valueAsNumber : value;
    }
  }

  private roundTo(number: T | R | null, exp: number) {
    const base = 10;
    const significand = base ** exp;

    return Math.round(Number(number) * significand) / significand;
  }

  onBlur() {
    const trimStartRegexp = /^[.]/;
    const trimSignOnlyRegexp = /^[-+]$/;
    const trimStartNegativeRegexp = /^[-][.]/;
    const trimStartPositiveRegexp = /^[\+][.]/;
    const trimPointOnlyRegexp = /[.]$/;
    const trimSignReplace = '';
    const trimStartReplace = '0.';
    const trimStartNegativeReplace = '-0.';
    const trimStartPositiveReplace = '+0.';
    const trimEndReplace = '';
    const value = this.control.value?.toString() as R | null;

    const cleanedUpValue = value
      ?.replace(trimStartNegativeRegexp, trimStartNegativeReplace)
      .replace(trimStartPositiveRegexp, trimStartPositiveReplace)
      .replace(trimStartRegexp, trimStartReplace)
      .replace(trimPointOnlyRegexp, trimEndReplace)
      .replace(trimSignOnlyRegexp, trimSignReplace) as R;

    if (!cleanedUpValue) {
      return this.control.setValue(this.formatViewValue(null));
    }

    const formattedValue = this.formatViewValue(cleanedUpValue);

    if (formattedValue !== value) {
      this.control.setValue(formattedValue ?? null);
    }
    this.updateToFormat();
  }

  onFocusOut() {
    this.ctrl.control?.updateValueAndValidity();
    this.ctrl.control?.markAsTouched();
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    const data = event.clipboardData?.getData('text/plain');
    const value = this.control.value as R | null;

    const pastedValue = data?.replace(/[,\s]/gm, '') as R;

    const formattedValue = this.formatViewValue(pastedValue ?? null);

    if (formattedValue !== value) {
      this.control.setValue(formattedValue ?? null);
    }
  }

  onFocus() {
    this.onTouched?.();
  }

  focus() {
    this.input.focus();
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
}
