import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { ERPCheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [ERPCheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatFormFieldModule,
    MatCheckboxModule
  ],
  exports: [ERPCheckboxComponent, ReactiveFormsModule]
})
export class ERPCheckboxModule {}
