import { Observable } from 'rxjs/internal/Observable';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService } from '../abstracts';

@Injectable({ providedIn: 'root' })
export class ERPReportsService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('');
  }

  protected get baseUrl() {
    return `${this.apiConfigService.domain}/gateway/api/dashboard/dashboards`;
  }

  getReportsList(): Observable<[{ id: string; name: string }]> {
    return this.get<[{ id: string; name: string }]>({}, this.baseUrl, {}, true);
  }
}
