import { Observable, fromEvent } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { ERPPageIdentifierService } from '../services';

const MIN_COLUMN_WIDTH = 90;
const DEFAULT_COLUMN_WIDTH = 160;
const PARSE_INT_RADIX = 10;

export const ACTION_COLUMN_NAMES = [
  'action',
  'actions',
  'isSelected',
  'selected',
  'delete',
  'delete-action',
  'save-action',
  'change',
  'close',
  'save'
];

@Directive({
  selector: '[erpResizeTable]'
})
export class ERPResizeTableDirective implements AfterViewInit {
  private table: HTMLElement;
  private flexColumn: HTMLElement;
  private flexChildsColumn: HTMLElement[] | [];
  private resizableColumns: HTMLElement[];
  private isHorizontalScroll = false;
  private initialTableWidth: number;

  private actionColumnNames = ACTION_COLUMN_NAMES;
  private fixedSizeClassName = '.fixed-size';

  @Input() storageIdentifier: string;

  @Input() set erpOrderColumns(data: string[]) {
    setTimeout(() => {
      this.setupResizing();
      this.restoreWidth();
    });
  }

  private get actionColumnSelector() {
    const colNamesList = this.actionColumnNames.map(c => `.mat-column-${c}`);
    colNamesList.push(this.fixedSizeClassName);

    return colNamesList.join(',');
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private pageIdentifierService: ERPPageIdentifierService
  ) {
    this.table = this.el.nativeElement;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setupResizing();
      this.restoreWidth();
    });
  }

  setupResizing() {
    this.initialTableWidth = this.el.nativeElement.offsetWidth;
    this.resizableColumns = Array.from(this.el.nativeElement.querySelectorAll(`th:not(${this.actionColumnSelector})`));
    this.flexColumn = this.resizableColumns[this.resizableColumns.length - 1] as HTMLElement;
    this.isHorizontalScroll = this.el.nativeElement.scrollWidth > this.el.nativeElement.parentElement.offsetWidth;

    const flexChildClass = Array.from(this.flexColumn?.classList || []).find(c => c.includes('mat-column-'));
    this.flexChildsColumn = flexChildClass
      ? Array.from(this.el.nativeElement.querySelectorAll(`td.${flexChildClass}`))
      : [];

    this.resizableColumns
      .map(column => column as HTMLElement)
      .forEach(column => {
        const holder = column.querySelector('.resize-holder');

        if (holder) {
          return;
        }

        const resizer = this.renderer.createElement('span');
        this.renderer.addClass(resizer, 'resize-holder');

        this.renderer.appendChild(column, resizer);

        this.getMouseDownObservable(resizer, column).subscribe(width => {
          const parentClass = Array.from(column.classList).find(c => c.includes('mat-column-'));
          const childs: HTMLInputElement[] = Array.from(
            this.el.nativeElement.querySelectorAll(`td:not(${this.actionColumnSelector}).${parentClass}`)
          );

          this.renderer.setStyle(column, 'width', `${width}px`);
          childs.forEach(child => this.renderer.setStyle(child, 'width', `${width}px`));
          this.fixColumnsWidth(column);
        });
      });
  }

  private getMouseDownObservable(resizer: HTMLElement, column: HTMLElement): Observable<number> {
    return fromEvent<MouseEvent>(resizer, 'mousedown').pipe(
      switchMap(() => {
        const { width, right } = column.getBoundingClientRect() as DOMRect;
        const minWidth = this.getMinWidth(column);

        return this.getMouseMoveObservable(column, width, right, minWidth);
      })
    );
  }

  private getMouseMoveObservable(
    column: HTMLElement,
    width: number,
    right: number,
    minWidth: number
  ): Observable<number> {
    return fromEvent<MouseEvent>(document, 'mousemove').pipe(
      tap(e => {
        this.renderer.addClass(this.table, 'resizing');
        this.handleFlex(column);
      }),
      map(({ clientX }) => {
        const newWidth = width + clientX - right;

        return Math.max(newWidth, minWidth);
      }),
      takeUntil(this.mouseUp$)
    );
  }

  private get mouseUp$(): Observable<Event> {
    return fromEvent(document, 'mouseup').pipe(
      tap(e => {
        this.renderer.removeClass(this.table, 'resizing');
        this.saveWidths();
      })
    );
  }
  private saveWidths() {
    const tableKey = this.getTableKey();

    const columnWidths = this.resizableColumns.map(col => col.offsetWidth);
    localStorage.setItem(tableKey, JSON.stringify(columnWidths));
  }

  private restoreWidth() {
    const widths = this.getWidthArray();

    this.resizableColumns.forEach(column => {
      const parentClass = Array.from(column.classList).find(c => c.includes('mat-column-'));
      const childs: HTMLElement[] = Array.from(
        this.el.nativeElement.querySelectorAll(`td:not(${this.actionColumnSelector}).${parentClass}`)
      );

      const columnIndex = this.resizableColumns.indexOf(column);
      let width = Math.max(
        widths[columnIndex] ?? 0,
        column.offsetWidth ?? 0,
        MIN_COLUMN_WIDTH,
        this.getMinWidth(column)
      );
      if (width === 0) {
        width = DEFAULT_COLUMN_WIDTH;
      }
      let widthValue = `${width}px`;

      if (column === this.flexColumn) {
        const minFlexWidth = this.getMinWidth(column);
        const flexInBoundaries = this.flexColumn.offsetWidth > minFlexWidth;

        widthValue = flexInBoundaries ? `${this.flexColumn.offsetWidth}px` : widthValue;
      }

      this.renderer.setStyle(column, 'width', widthValue);
      childs.forEach(child => this.renderer.setStyle(child, 'width', widthValue));
      this.handleFlex(column);
    });
  }

  private getWidthArray() {
    const tableKey = this.getTableKey();

    return JSON.parse(localStorage.getItem(tableKey) ?? '[]');
  }

  private getTableKey() {
    return `${this.pageIdentifierService.getWidthIdentifier(this.storageIdentifier, this.table)}`;
  }

  private getMinWidth(column: HTMLElement) {
    const cssMinWidth = this.getStyleProperty(column, 'min-width');
    return cssMinWidth > 0 ? cssMinWidth : MIN_COLUMN_WIDTH;
  }

  private getStyleProperty(el: HTMLElement, propertyName: string) {
    return parseInt(getComputedStyle(el).getPropertyValue(propertyName), PARSE_INT_RADIX);
  }

  // Methods below are introduced for more convenient resizing behavior when table width: 100% (KO)
  private fixColumnsWidth(column: HTMLElement) {
    if (column === this.flexColumn) {
      this.resizableColumns
        .filter(col => col !== this.flexColumn)
        .forEach(col => {
          const parentClass = Array.from(col.classList).find(c => c.includes('mat-column-'));
          const childs: HTMLElement[] = Array.from(
            this.el.nativeElement.querySelectorAll(`td:not(${this.actionColumnSelector}).${parentClass}`)
          );

          const actualWidth = col.getBoundingClientRect().width;
          this.renderer.setStyle(col, 'width', `${actualWidth}px`);
          childs.forEach(child => this.renderer.setStyle(child, 'width', `${actualWidth}px`));
        });
    }

    this.handleFlex(column);
  }

  private handleFlex(column: HTMLElement) {
    if (column === this.flexColumn) {
      return;
    }

    const currentFlexWidth = this.flexColumn.offsetWidth;
    const minFlexWidth = this.isHorizontalScroll ? currentFlexWidth : this.getMinWidth(this.flexColumn);
    const flexIsInBoundaries = currentFlexWidth > minFlexWidth;
    const flexWidth = this.isHorizontalScroll
      ? flexIsInBoundaries
        ? 'auto'
        : `${Math.max(currentFlexWidth, minFlexWidth)}px`
      : `${currentFlexWidth}px`;

    this.renderer.setStyle(this.flexColumn, 'width', flexWidth);
    this.flexChildsColumn.forEach(child => this.renderer.setStyle(child, 'width', flexWidth));
  }
}
