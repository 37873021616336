import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { ERPToasterService } from '../../../../components/src/lib/modules/toaster/services';
import { ICollectionResponse, ICollectionResponseMetadata, IMetadataPaging } from '../abstracts';
import { IWorkCentersList } from '../interfaces';
import { ERP_DEFAULT_TABLE_PAGING } from '../../../../core/src/lib/providers';
import { ERPWorkCenterService } from '../services';

@Injectable({ providedIn: 'any' })
export class ERPWorkCentersListResolver {
  constructor(
    readonly toasterService: ERPToasterService,
    @Inject(ERP_DEFAULT_TABLE_PAGING) readonly paging: IMetadataPaging,
    readonly workCentersService: ERPWorkCenterService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    const initState: ICollectionResponseMetadata = {
      paging: this.paging,
      sorting: [{ by: 'name', order: 'asc' }],
      filtering: []
    };
    const query = route.queryParamMap.get('query');
    let metadata = initState;

    if (query) {
      metadata = JSON.parse(query);
    }

    return this.workCentersService.getWorkCenters({ query: JSON.stringify(metadata) }).pipe(
      catchError(error => {
        this.toasterService.error('error.default');

        return of({ data: [], metadata });
      })
    );
  }
}
