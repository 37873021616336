import { Observable } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import { AutoCleanupFeature, Features, IDestroyable, ValidatorFeature, ValueAccessorFeature } from '@erp/shared';

@Component({
  selector: 'erp-attachment-file',
  templateUrl: './attachment-file-upload.component.html',
  styleUrls: ['./attachment-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPAttachmentFileComponent implements IDestroyable {
  readonly destroyed$: Observable<unknown>;

  @ViewChild('input', { static: true })
  readonly fileUpload: ElementRef<HTMLInputElement>;
  @Input() isArray = false;
  @Input() disabled = false;
  @Input() multiple = true;
  @Output()
  readonly uploadNewFile: EventEmitter<File | File[]> = new EventEmitter();

  constructor(readonly changeDetector: ChangeDetectorRef) {}

  onSelectFiles() {
    if (this.disabled) {
      return;
    }

    const fileUpload = this.fileUpload.nativeElement;
    const files = Array.from(fileUpload.files ?? []);

    if (this.isArray) {
      this.uploadNewFile.emit(files);
    } else {
      for (const file of files) {
        this.uploadFile(file);
      }
    }
  }

  onUploadClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.onUpload();
  }

  onUpload() {
    const { nativeElement } = this.fileUpload;

    nativeElement.value = '';
    nativeElement.click();
  }

  private uploadFile(file: File) {
    this.uploadNewFile.emit(file);
  }
}
