import { Injectable } from '@angular/core';

import { IMaterialReleaseItem, IMaterialReleaseItemResponse } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPMaterialReleaseFactory {
  toRequest(release: IMaterialReleaseItem): IMaterialReleaseItemResponse {
    return {
      id: release.id,
      rowVersion: release.rowVersion,
      lines: release.lines,
      ...release.mainInfo
    };
  }

  fromResponse(releaseResponse: IMaterialReleaseItemResponse): IMaterialReleaseItem {
    const { id, rowVersion, lines } = releaseResponse;

    return {
      id,
      rowVersion,
      lines,
      mainInfo: {
        address: releaseResponse?.address ?? {},
        customerId: releaseResponse?.customerId,
        customerName: releaseResponse?.customerName,
        customerPoNumber: releaseResponse?.customerPoNumber,
        customerReleaseNumber: releaseResponse?.customerReleaseNumber,
        estimatedShippingDate: releaseResponse?.estimatedShippingDate,
        isBlindShipping: releaseResponse?.isBlindShipping,
        materialReleaseNumber: releaseResponse?.materialReleaseNumber,
        materialReleaseStatusId: releaseResponse?.materialReleaseStatusId,
        notes: releaseResponse?.notes,
        reference: releaseResponse?.reference,
        reference2: releaseResponse?.reference2,
        releaseDate: releaseResponse?.releaseDate,
        releasedByUserId: releaseResponse?.releasedByUserId,
        releasedByUserName: releaseResponse?.releasedByUserName,
        salesOrderId: releaseResponse?.salesOrderId,
        salesOrderNumber: releaseResponse?.salesOrderNumber,
        phone: releaseResponse.phone,
        email: releaseResponse.email,
        customerPoNumber2: releaseResponse.customerPoNumber2,
        releasedByContactName: releaseResponse?.releasedByContactName,
        releasedByContactId: releaseResponse.releasedByContactId,
        isFrontCollarDirection: releaseResponse.isFrontCollarDirection,
        materialReleaseTypeId: releaseResponse.materialReleaseTypeId,
        location: releaseResponse.location,
        isBlindShipment: releaseResponse.isBlindShipment
      }
    };
  }
}
