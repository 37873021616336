import { Injectable } from '@angular/core';
import { ERPApiConfigService } from './api-config.service';

@Injectable()
export class ReportOpenerService {
  constructor(private readonly $apiConfig: ERPApiConfigService) {}

  open(url: string) {
    window.open(`${this.$apiConfig.domain}/${url}`, '_blank');
  }
}
