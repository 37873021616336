<mat-form-field appearance="outline" [@.disabled]="true">
  <input
    matInput
    [formControl]="control"
    [erpFocus]="isFocused"
    [placeholder]="placeholder"
    autocomplete="off"
    [matAutocomplete]="autocomplete"
    [maxlength]="100"
    [readonly]="readonly"
    (input)="onInput()"
    (keydown.enter)="onEnterKeyDown($event)"
    (keyup.backspace)="onBackspaceKeyUp($event)"
    (keyup.enter)="onSelectValue()"
  />

  <erp-icon
    color="light-gray"
    [class.cursor-pointer]="!control.disabled"
    variant="small"
    *ngIf="clearable && length"
    icon="action:cross-circle"
    matSuffix
    (click)="onClearValue()"
  ></erp-icon>

  <erp-icon
    *ngIf="multiselect && control.value && control.valid"
    [color]="control.disabled ? 'light-gray' : 'primary'"
    [class.cursor-pointer]="!control.disabled"
    icon="action:add"
    matSuffix
    (click)="onSelectMulti()"
  ></erp-icon>

  <erp-icon
    color="primary"
    [class.cursor-pointer]="!control.disabled"
    *ngIf="icon"
    [icon]="icon"
    matSuffix
    (click)="onSelectValue()"
    [color]="control.disabled ? 'light-gray' : 'primary'"
  ></erp-icon>

  <ng-container matSuffix>
    <ng-content select="erp-icon"></ng-content>
  </ng-container>

  <mat-autocomplete
    #autocomplete
    panelWidth="auto"
    [autoActiveFirstOption]="true"
    [displayWith]="displayFn"
    [@.disabled]="true"
    (opened)="onAutocompleteOpen()"
    (optionSelected)="onSelectOption($event)"
  >
    <ng-container *ngIf="options$ | async as options">
      <ng-container *ngIf="options && !options.length && length >= minlength">
        <mat-option class="mat-option-empty" disabled>
          <ng-container i18n="@@common.form.autocomplete.not-found"> No matches found </ng-container>
        </mat-option>
      </ng-container>

      <ng-container *ngFor="let option of options; trackBy: trackByFn">
        <mat-option [value]="option">
          {{ labelFn(option) }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="canAddNew">
        <mat-option class="text-bold" [value]="getNewOption(control.value)">
          {{ control.value }} (New value)
        </mat-option>
      </ng-container>

      <ng-container *ngIf="loading$ | async">
        <mat-option class="text-center" disabled>
          <mat-spinner color="primary" [diameter]="20"></mat-spinner>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="control.hasError('required')">
    <ng-container i18n="@@common.form.errors.required"> This field is required </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('nomatch')">
    <ng-container i18n="@@common.form.autocomplete.errors.no-match"> No matches </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('maxlength')">
    <ng-container *ngIf="control.getError('maxlength') as error" i18n="@@common.form.errors.max-length">
      Use {{ error.requiredLength }} {error.requiredLength, plural, =1 {character} other {characters}} max
    </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('integer')" i18n="@@common.form.errors.integer"> Use integers only </mat-error>
  <mat-error *ngIf="control.hasError('contactLastName')">
    <ng-container> {{ control.getError('contactLastName') }} </ng-container>
  </mat-error>
  <mat-error *ngIf="control.hasError('missingValueType')">
    <ng-container> {{ control.getError('missingValueType') }} </ng-container>
  </mat-error>
</mat-form-field>
