import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { NgControl, UntypedFormControl } from '@angular/forms';

import { AutoCleanupFeature, BaseControlComponent, ERPFormStateDispatcher, Features } from '@erp/shared';

import { ISelectOption } from '../../../select';

@Component({
  selector: 'erp-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature()])
export class ERPRadioComponent<T> extends BaseControlComponent<T> implements OnInit {
  constructor(
    @Inject(NgControl) readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional() @SkipSelf() readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }
  readonly destroyed$: Observable<unknown>;
  @Input() readonly options: ISelectOption[] = [];

  readonly control = new UntypedFormControl(null);
  @Output() readonly changed = new EventEmitter();

  @Input() readonly valueFn = (option: ISelectOption | null) => option?.id;
  @Input() readonly labelFn = (option: ISelectOption | null) => option?.value;

  ngOnInit() {
    this.control.setValidators(this.ctrl.control?.validator ?? null);
    this.control.setAsyncValidators(this.ctrl.control?.asyncValidator ?? null);
    this.onValidatorChange?.();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;

      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  trackByFn(option: ISelectOption<string>) {
    return option.id;
  }
}
