import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreviewerRoutingModule } from './previewer-routing.module';
import { PreviewerComponent } from './components';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { ERPNumberModule } from '../number';
import { ERPLabelModule } from '../label';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PreviewerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PreviewerRoutingModule,
    PdfViewerModule,
    ERPButtonModule,
    ERPIconModule,
    ERPLabelModule,
    MatCardModule,
    ERPNumberModule
  ],
  exports: [PreviewerComponent]
})
export class ERPPreviewerModule {}
