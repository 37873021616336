<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container>Check-in</ng-container>
</h1>
<section class="dialog-content erp-tablet-module" mat-dialog-content>
  <div [formGroup]="form" class="padding-top-3 grid grid-column-gap-c">
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Facility:</ng-container>
      </erp-label>
      <erp-select
        [isFocused]="true"
        formControlName="facilityName"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="facilities$ | async"
        (changed)="onFacilitiesChanged($event)"
      >
      </erp-select>
    </div>
    <div class="col-6">
      <erp-label class="margin-bottom-1" [required]="true">
        <ng-container>Shift:</ng-container>
      </erp-label>
      <erp-select
        formControlName="shiftName"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="shifts$ | async"
        (changed)="onShiftChanged($event)"
      >
      </erp-select>
    </div>
    <div class="col-6">
      <erp-label class="margin-bottom-1">
        <ng-container>Line:</ng-container>
      </erp-label>
      <erp-select
        formControlName="lineName"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="lines"
        (changed)="onLineChanged($event)"
      >
      </erp-select>
    </div>
    <div class="col-6">
      <erp-label [required]="form.get('cncName')?.enabled" class="margin-bottom-1">
        <ng-container>CNC:</ng-container>
      </erp-label>
      <erp-select
        formControlName="cncName"
        placeholder="Select"
        i18n-placeholder="@@common.select.placeholder"
        [options]="cncs$ | async"
        (changed)="onCNCChanged($event)"
      >
      </erp-select>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end erp-app erp-app-tablet" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" (click)="onCancel()" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button [disabled]="!form.valid" medium class="dialog-action-confirm" color="accent" (click)="onCreate()">
    <ng-container> Check-in </ng-container>
  </erp-button>
</section>
<ng-template #checkIn let-startDate="startDate">
  <b>
    <ng-container i18n="@@tablet.check-in.toast-title"> You are checked-in </ng-container>
  </b>
  <div>
    <ng-container i18n="@@tablet.check-in.toast-message"> Session started at {{ startDate | erpTime }} </ng-container>
  </div>
</ng-template>
