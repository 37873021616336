import '@angular/localize/init';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { NgControl, UntypedFormControl, Validators } from '@angular/forms';

import {
  AutoCleanupFeature,
  BaseControlComponent,
  CustomValidators,
  ERPFormStateDispatcher,
  Features
} from '@erp/shared';

@Component({
  selector: 'erp-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Features([AutoCleanupFeature()])
export class ERPTimePickerComponent<T extends Date, R extends string>
  extends BaseControlComponent<T, R>
  implements OnInit
{
  readonly destroyed$: Observable<unknown>;
  readonly control = new UntypedFormControl(null);
  @Input() readonly placeholder: string | null = 'HH:MM';
  @Input() readonly hoursMode = false;
  @Input() readonly minsMode = false;
  @Input() readonly isFocused: boolean;

  mask: string;

  constructor(
    @Inject(LOCALE_ID)
    readonly currentLocale: string,
    @Inject(NgControl)
    readonly ctrl: NgControl,
    readonly changeDetector: ChangeDetectorRef,
    @Optional()
    @SkipSelf()
    readonly formState: ERPFormStateDispatcher | null
  ) {
    super();
    this.ctrl.valueAccessor = this;
  }

  ngOnInit() {
    this.mask = this.hoursMode ? 'hh:m0' : this.minsMode ? 'm0' : 'Hh:m0';

    this.setValidators();

    this.formState?.onSubmit.listen.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.control.markAsTouched();
      this.changeDetector.markForCheck();
    });

    this.ctrl.control?.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const errors = this.ctrl.control?.errors ?? null;
      this.control.setErrors(errors);
      this.changeDetector.markForCheck();
    });
  }

  private setValidators() {
    const validator = this.hoursMode
      ? CustomValidators.hours
      : this.minsMode
        ? CustomValidators.minutes
        : CustomValidators.time;
    const validators = this.ctrl.control?.validator
      ? Validators.compose([validator, this.ctrl.control.validator])
      : validator;
    const asyncValidators = this.ctrl.control?.asyncValidator ?? null;

    this.control.setValidators(validators);
    this.control.setAsyncValidators(asyncValidators);

    this.ctrl.control?.setValidators(validators);
    this.ctrl.control?.setAsyncValidators(asyncValidators);

    this.onValidatorChange?.();
  }

  onSetCurrentTime() {
    if (this.control.disabled) {
      return;
    }
    const date = new Date();
    const { value } = this.control;

    let hours = date.getHours().toString();
    hours = hours.length === 1 ? `0${hours}` : hours;
    let minutes = date.getMinutes().toString();
    minutes = minutes.length === 1 ? `0${minutes}` : minutes;

    const time = `${hours}:${minutes}`;
    if (time !== value) {
      this.control.setValue(time);
    }
  }

  onEnterKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }

  onFocus() {
    this.onTouched?.();
  }

  setDisabledState(disabled: boolean): void {
    super.setDisabledState(disabled);
    this.changeDetector.markForCheck();
  }
}
