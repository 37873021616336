import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ERPConfirmModule } from '../../confirm';

import { ERPCloseLineDialogComponent, ERPCloseMRLineDialogComponent } from './components';

@NgModule({
  declarations: [ERPCloseLineDialogComponent, ERPCloseMRLineDialogComponent],
  imports: [CommonModule, ERPConfirmModule],
  exports: [ERPCloseLineDialogComponent, ERPCloseMRLineDialogComponent]
})
export class ERPCloseDialogModule {}
