<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container i18n="@@common.table.customize-columns">Customize columns</ng-container>
</h1>
<ng-container>
  <erp-button color="primary" variant="icon" class="dialog-close" mat-dialog-close>
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</ng-container>
<section [formGroup]="form" class="dialog-content grid grid-row-gap-p" cdkScrollable mat-dialog-content>
  <div class="col-12 grid grid-row-gap-p" cdkDropList (cdkDropListDropped)="drop($event)">
    <div
      *ngFor="let col of orderedColumns"
      cdkDrag
      class="col-12 col-item flex justify-content-space-between align-items-center"
    >
      <div class="flex align-items-center">
        <erp-checkbox [formControlName]="col" class="margin-right-2"></erp-checkbox>
        {{ source.get(col)?.name }}
      </div>

      <erp-icon [icon]="'action:drag'"></erp-icon>
    </div>
  </div>
  <div class="col-12 grid grid-row-gap-p">
    <div
      *ngFor="let col of disabledColumns"
      class="col-12 col-item col-item-disabled flex justify-content-space-between align-items-center"
    >
      <div class="flex align-items-center">
        <erp-checkbox [formControlName]="col" class="margin-right-2"></erp-checkbox>
        {{ source.get(col)?.name }}
      </div>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button class="dialog-action-confirm" color="accent" (click)="onSave()">
    <ng-container i18n="@@common.buttons.save"> Save </ng-container>
  </erp-button>
</section>
