import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ModulesLinks } from '@erp/shared';

@Component({
  standalone: true,
  selector: 'erp-extended-sku-cell',
  template: `
    <a [routerLink]="[getLink(sku), sku]" target="_blank" (click)="$event.stopPropagation()" class="sku-id">
      {{ sku }}
    </a>

    <span class="sku-description">{{ description }}</span>
  `,
  styleUrls: ['./extended-sku-cell.component.scss'],
  imports: [RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPExtendedSKUCellComponent {
  readonly links = new Map<string, string>([
    ['CAS', ModulesLinks.Casing],
    ['TUB', ModulesLinks.Tubing],
    ['LIP', ModulesLinks.LinePipe],
    ['DRP', ModulesLinks.DrillPipe],
    ['MAR', ModulesLinks.Marker],
    ['PUJ', ModulesLinks.PupJoint],
    ['CRO', ModulesLinks.CrossOver],
    ['COS', ModulesLinks.CouplingStock],
    ['COM', ModulesLinks.CouplingMaterial],
    ['COU', ModulesLinks.Coupling],
    ['PRO', ModulesLinks.Protector],
    ['SER', ModulesLinks.Service],
    ['CON', ModulesLinks.Consumables],
    ['MAP', ModulesLinks.MaintenancePart],
    ['OPP', ModulesLinks.OperationPart],
    ['OTH', ModulesLinks.Other]
  ]);

  @Input() sku: string;
  @Input() description: string;
  @Input() link: string[] = [];

  getLink(id: string): string {
    const startIndex = 0;
    const endIndex = 3;

    return this.links.get(id?.substring(startIndex, endIndex)) || '';
  }
}
