import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export function Cache() {
  const cache = new Map();

  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const key = JSON.stringify(args);
      if (cache.has(key)) {
        return cache.get(key);
      }

      const result = originalMethod.apply(this, args);

      if (result instanceof Observable) {
        const sharedResult = result.pipe(shareReplay(1));
        cache.set(key, sharedResult);
        return sharedResult;
      } else if (result instanceof Promise) {
        result.then(data => cache.set(key, Promise.resolve(data)));
        return result;
      } else {
        cache.set(key, result);
        return result;
      }
    };

    return descriptor;
  };
}
