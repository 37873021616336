import { Injectable } from '@angular/core';
import { DictionaryService } from '../services';

@Injectable({ providedIn: 'any' })
export class ERPDefectsResolver {
  constructor(private readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.allDefects;
  }
}
