import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { IMetadataFiltering } from '@erp/shared';

import { ISelectOption } from '../../../select';
import { BaseTableFilter } from '../../abstracts';

@Component({
  selector: 'erp-table-select-filter',
  templateUrl: './table-select-filter.component.html',
  styleUrls: ['./table-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPTableSelectFilterComponent extends BaseTableFilter implements OnInit {
  form: UntypedFormGroup;
  options: ISelectOption<number | boolean>[];
  readonly radioModeLength = 2;
  displayFn: (value: ISelectOption, id: number) => void;
  labelFn = ({ value }: ISelectOption) => value;
  valueFn: (option: any) => any = option => option.id;

  constructor(
    readonly dialogRef: MatDialogRef<ERPTableSelectFilterComponent>,
    @Inject(MAT_DIALOG_DATA)
    readonly data: {
      trigger: HTMLElement;
      filter: IMetadataFiltering;
      options: ISelectOption<number | boolean>[];
      valueFn?: ((option: any) => any) | undefined;
      labelFn(value: ISelectOption): string;
      displayFn(value: ISelectOption, index: number): string;
    },
    readonly dialogElementRef: ElementRef<HTMLElement>
  ) {
    super();
  }

  ngOnInit() {
    if (this.data.valueFn) {
      this.valueFn = this.data.valueFn;
    }

    if (this.data.labelFn) {
      this.labelFn = this.data.labelFn;
    }

    if (this.data.displayFn) {
      this.displayFn = this.data.displayFn;
    }

    this.options = this.data.options || [];
    this.form = new UntypedFormGroup({
      select: new UntypedFormControl(this.data?.filter?.match1)
    });
    this.setDialogPosition();
  }

  onApply() {
    const match1 = this.form.get('select')?.value;
    const { by } = this.data.filter;

    if (match1 !== null && match1 !== undefined) {
      const filter: IMetadataFiltering = { by, match1, op: 'eq' };

      return this.dialogRef.close(filter);
    }

    return this.dialogRef.close(null);
  }
}
