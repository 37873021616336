import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ERPApiConfigService {
  private _apiDomain: string;
  private _apiUrl: string;
  constructor() {}

  get url(): string | null {
    const isEmpty = !this._apiDomain && !this._apiUrl;
    return isEmpty ? null : `${this._apiDomain}${this._apiUrl}`;
  }

  get domain(): string {
    return this._apiDomain;
  }
}
