import { Observable, map, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'any' })
export class DiscardChangesGuard {
  canDeactivate(
    component: IDiscardChanges,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (component.canDiscardChanges instanceof Function) {
      const result = component.canDiscardChanges(nextState);
      if (result instanceof Observable) {
        return result.pipe(map(bool => !!bool));
      }

      if (result instanceof Promise) {
        return result.then(bool => !!bool);
      }

      return !!result;
    }

    return of(true);
  }
}

export interface IDiscardChanges {
  canDiscardChanges(nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean;
}
