import { Inject, Injectable } from '@angular/core';

import { ISelectOption } from '../../../../components/src/lib';
import { IPipeSizing } from '../../../../inventory/src/lib/modules/product-types/modules/common/interfaces';
import { IMetadataPaging } from '../abstracts/http';
import { TableQueryBuilder } from '../builders';
import { DESKTOP_DOCUMENT_PAGE_URLS, IDocumentPageURL, TABLET_DOCUMENT_PAGE_URLS } from '../constants';
import { IInventorySpecialty } from '../interfaces';
import { ERP_DEFAULT_TABLE_PAGING } from '../../../../core/src/lib/providers';
import { Dictionary } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ERPUtilsService {
  constructor(
    @Inject(ERP_DEFAULT_TABLE_PAGING)
    readonly paging: IMetadataPaging
  ) {}

  getUriByDocumentID(documentTypeId: number, isTabletModule = false) {
    const primaryUrlList = isTabletModule ? TABLET_DOCUMENT_PAGE_URLS : DESKTOP_DOCUMENT_PAGE_URLS;
    const secondaryUrlList = isTabletModule ? DESKTOP_DOCUMENT_PAGE_URLS : TABLET_DOCUMENT_PAGE_URLS;

    const documentPage = findPage(primaryUrlList) ?? findPage(secondaryUrlList);

    return documentPage ? documentPage.uri : '';

    function findPage(pages: IDocumentPageURL[]) {
      return pages.find(({ id }) => documentTypeId === id);
    }
  }

  goToTransactionsHistory(match1: string): string {
    const query = new TableQueryBuilder({})
      .withFiltering([
        {
          by: 'materialNumber',
          op: 'contains',
          match1
        }
      ])
      .withPaging({ ...this.paging })
      .serialize();

    return query;
  }

  generateSKUDescription(data: IPipeSizing, dictionaryData: Dictionary<ISelectOption[] | IInventorySpecialty[]>) {
    return (
      (this.checkValue(data, 'size') ? `${Number(data.size.value).toFixed(3)}''${!data.wall ? ' ' : ''}` : '') +
      (this.checkValue(data, 'wall') ? `x${Number(data.wall.value).toFixed(3)}'' ` : '') +
      (data.weightPerFoot ? `${Number(data.weightPerFoot).toFixed(2)}# ` : '') +
      (this.checkValue(data, 'grade') ? `${data.grade.value} ` : '') +
      (this.checkValue(data, 'connection') ? `${data.connection.value} ` : '') +
      (this.checkValue(data, 'thread') ? `${data.thread.value} ` : '') +
      (this.checkValue(data, 'type') ? `${data.type?.value} ` : '') +
      (this.checkValue(data, 'design') ? `${data.design.value} ` : '') +
      (this.checkValue(data, 'range') ? `${data.range.value} ` : '') +
      (this.checkValue(data, 'condition') ? `${data.condition.value} ` : '') +
      (this.checkValue(data, 'specialty') ? `${data.specialty?.value}` : '')
    );
  }

  private checkValue(data: IPipeSizing, propName: keyof Omit<IPipeSizing, 'weightPerFoot'>) {
    return data[propName] && data[propName]?.value;
  }

  getLabelValue<K, V>(value: K, dictionary: ISelectOption<K, V>[]) {
    const item = dictionary?.find(x => x.id === value);

    return item?.value;
  }
}
