import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  mergeMap,
  of,
  single,
  takeUntil,
  throwError
} from 'rxjs';
import { ERPAttachmentsService, IAttachment } from '../../../attachment';
import { ERPToasterService } from '../../../toaster/services';

@Component({
  selector: 'erp-previewer',
  templateUrl: './previewer.component.html',
  styleUrls: ['./previewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewerComponent implements OnInit {
  readonly destroyed$ = new Subject<any>();
  src: string;
  attachmentPreviewError$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  page: number = 1;
  zoom = 1;

  @Input() readonly downloadFn: (fileId: number) => Observable<Blob> = () => EMPTY;

  constructor(
    public readonly $attachmentService: ERPAttachmentsService,
    private readonly $changeDetector: ChangeDetectorRef,
    private readonly $route: ActivatedRoute,
    private readonly $toasterService: ERPToasterService
  ) {}

  ngOnInit(): void {
    this.attachment.pipe(takeUntil(this.destroyed$)).subscribe((response: Blob) => {
      const file = new Blob([response], { type: 'application/pdf' });
      this.src = URL.createObjectURL(file);
      this.$changeDetector.markForCheck();
    });
  }

  get attachment(): Observable<Blob> {
    return this.$attachmentService.selectedElement.pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged(),
      filter((element: IAttachment | null) => !!element),
      mergeMap((attachment: IAttachment | null) => {
        return this.downloadFn((attachment as IAttachment).id as number).pipe(single());
      }),
      mergeMap((response: Blob | null) => {
        if (!response) {
          const message = "Can't create download url";
          return throwError({ err: { error: { error: { message } } } });
        }

        return of(response);
      }),
      catchError(err => {
        this.$toasterService.error(err?.error?.error?.message || 'error.default');

        return of(null);
      }),
      filter((response: Blob | null) => !!response)
    ) as Observable<Blob>;
  }

  get selectedAttachment(): IAttachment | null {
    return this.$attachmentService.selectedElement.value;
  }

  onPlusZoom(): void {
    this.zoom += 0.1;
  }

  onMinusZoom(): void {
    this.zoom -= 0.1;
  }

  onError($event: { name: string }) {
    if ($event?.name === 'InvalidPDFException') {
      const err = $localize`:@@components.previewer.error.invalid-pdf:Preview is not available, please download the document.`;
      this.attachmentPreviewError$.next(err);
    }
  }

  onOpenPDFInNewTab(): void {
    window.open(this.src, '_blank');
  }

  onPrintPDFAttachment(): void {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.src = this.src;

    iframe.onload = function () {
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.print();
      }
    };
  }

  onAfterLoadComplete(event: Event) {
    this.attachmentPreviewError$.next(null);
  }

  onDownloadAttachment() {
    const fileName = this.$attachmentService.selectedElement.value?.fileName as string;
    const attachmentId: number = this.$attachmentService.selectedElement.value?.id as number;

    this.downloadFn(attachmentId)
      .pipe(takeUntil(this.destroyed$), single())
      .subscribe(
        response => {
          const anchor = document.createElement('a');

          anchor.href = URL.createObjectURL(response);
          anchor.download = fileName;
          anchor.click();
        },
        error => {
          const message = error.error?.error?.message || 'error.default';
          this.$toasterService.error(message);
        }
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
