import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ERPLabelModule } from '../../label';
import { ERPRadioModule } from '../../radio';
import { ERPButtonModule } from '../../button';
import { ERPDatePickerModule } from '../../date-picker';
import { DictionaryService, ERPDateUtil } from '@erp/shared';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ERPIconModule } from '../../icon';
import { ERPAutocompleteModule } from '../../autocomplete/autocomplete.module';
import { map } from 'rxjs';

export interface IPrintPDFData {
  [key: string]: any;
}

@Component({
  standalone: true,
  templateUrl: './po-print-pdf.component.html',
  styleUrls: ['./po-print-pdf.component.scss'],
  imports: [
    ERPLabelModule,
    ERPRadioModule,
    ERPButtonModule,
    ERPDatePickerModule,
    ERPIconModule,
    MatDialogModule,
    ERPAutocompleteModule
  ]
})
export class ERPPOPrintPDFComponent {
  header: string | null;
  cancel: string | null;
  confirm: string | null;

  form = new FormGroup({
    timeZoneOffset: new FormControl(ERPDateUtil.caluclateTimeZoneDiffInMinutes()),
    uom: new FormControl(null, [Validators.required])
  });

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly data: IPrintPDFData,
    private readonly $dialogRef: MatDialogRef<any>,
    private readonly dictionaryService: DictionaryService
  ) {
    this.cancel = this.data.cancel;
    this.confirm = this.data.confirm;
    this.header = this.data.header;
  }

  onCancel() {
    this.$dialogRef.close(undefined);
  }

  onCreate() {
    if (this.form.invalid) return;

    this.$dialogRef.close({
      ...this.form.value
    });
  }

  uomDisplayFn = (value: any | string) => (value instanceof Object ? value?.symbol : value);
  uomValueFn = (value: any) => value;
  uomLabelFn = (value: any) => (value instanceof Object ? value?.symbol : value);

  uomOptionsFn = (searchVal: string) => {
    const query = searchVal.toLowerCase();
    return this.dictionaryService.inventoryUoms.pipe(
      map(uoms => {
        return uoms
          .filter(uom => uom.symbol.toLowerCase().includes(query) || uom.value.toLowerCase().includes(query))
          .sort((a, b) => a.symbol.localeCompare(b.symbol));
      })
    );
  };
}
