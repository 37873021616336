<div class="margin-4">
  <dx-dashboard-control
    [workingMode]="'ViewerOnly'"
    [dashboardId]="id"
    [loadDefaultDashboard]="false"
    style="display: block; width: 100%; height: 800px"
    [endpoint]="endpoint"
    (onDashboardInitialized)="onDashboardInitialized($event)"
  >
    <dxo-ajax-remote-service [headers]="headers"> </dxo-ajax-remote-service>
    <dxo-data-request-options
      itemDataRequestMode="BatchRequests"
      itemDataLoadingMode="OnDemand"
    ></dxo-data-request-options>
  </dx-dashboard-control>
</div>
