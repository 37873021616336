import { Observable, Subject, identity } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UntypedFormGroup, ValidationErrors, Validator } from '@angular/forms';

import { IDestroyable } from '../../lifecycle';
import { BaseControl } from '../base';

export abstract class BaseFormComponent<T, R = T> extends BaseControl<T> implements Validator, IDestroyable {
  readonly destroyed$: Observable<unknown> | Subject<unknown>;
  abstract readonly form: UntypedFormGroup;
  protected onValidatorChange?: () => void;
  protected onChanged?: (value: T) => void;
  protected onTouched?: () => void;

  protected readonly viewToModelParser?: (value: T | R | null) => T | R | null = identity;
  protected readonly modelToViewFormatter?: (value: T | R | null) => T | R | null = identity;

  get value(): T | null {
    return this.form.value;
  }

  writeValue(value: T | null): void {
    if (value) {
      return this.form.patchValue(this.modelToViewFormatter?.(value) as object, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: T) => void) {
    super.registerOnChange(fn);

    this.form.valueChanges.pipe<T>(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChanged?.(this.viewToModelParser?.(value) as T);
      this.onTouched?.();
    });
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  validate(): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return Object.entries(this.form.controls).reduce((acc, [key, control]) => {
      if (control.errors) {
        acc[key] = control.errors;
      }
      return acc;
    }, {} as any);
  }
}
