import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  forwardRef,
  Inject,
  Self,
  Optional
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Observable, Subject, identity, takeUntil } from 'rxjs';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ERPAdvancedSearchComponentService } from './advanced-search-component.service';

@Component({
  selector: 'erp-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent<T = any> implements ControlValueAccessor, OnInit, OnDestroy {
  private destroyed$ = new Subject();

  @Input() readonly advancedSearchCmpName: string = '';

  // autocomplete related props - START
  @Input() readonly icon = 'action:search';
  @Input() readonly autoselect = true;
  @Input() readonly labelFn = identity;
  @Input() readonly displayFn = identity;
  @Input() readonly optionsFn: (search: string | null, length?: number) => Observable<T[]>;
  @Input() readonly placeholder: string | null;
  @Input() readonly readonly: boolean;
  @Input() readonly clearable: boolean = true;
  @Input() readonly multiselect: boolean = false;
  @Input() readonly openOnFocus = false;
  @Input() readonly createNew = false;
  @Input() readonly labelProp: string | null;
  @Input() readonly isFocused: boolean;
  @Input() set disabled(value: boolean) {
    if (value) {
      this.control.disable();
    }
  }
  @Input() readonly populateValueByID: boolean = false;
  @Input() readonly minlength = 1;
  @Input() readonly makeInitialRequest = false;

  // form builder specific props
  @Input() readonly isFormBuilder: boolean = false;
  @Input() readonly optionsPaging: boolean = false;

  @Output() readonly changed = new EventEmitter<T | null>();
  @Output() readonly multiSelected = new EventEmitter<T | null>();
  // autocomplete related props - END

  control = new FormControl();
  registeredChangeFn: (value: T) => void;
  registeredTouchFn: () => void;

  constructor(
    private readonly $dialog: MatDialog,
    private readonly $cmpService: ERPAdvancedSearchComponentService,
    @Self()
    @Optional()
    @Inject(NgControl)
    readonly ctrl: NgControl
  ) {
    ctrl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(newValue => {
      this.onChange(newValue);
    });

    const validator = this.ctrl.control?.validator ?? null;
    this.control.setValidators(validator);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
  }

  onChange(event: any) {
    this.changed.emit(event);
    this.registeredChangeFn(event);
    this.registeredTouchFn();
  }

  onAdvancedSearch(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const cmp = this.$cmpService.getComponent(this.advancedSearchCmpName);

    if (cmp) {
      const dialogRef = this.$dialog.open(cmp);

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroyed$))
        .subscribe(data => {
          if (data) {
            this.control.setValue(data);
          }
        });
    }
  }

  writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this.registeredChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.registeredTouchFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
}
