import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { filter, identity, map } from 'rxjs';
import { ERPDateUtil, ERPQueryingService, ILoadingReportEnum, IOnHandReport, LoadingReportType } from '@erp/shared';
import { ReportOpenerService } from '@erp/core';

@Injectable()
export class ERPLogisticReportsService {
  readonly loadingReportEnum = ILoadingReportEnum;

  constructor(
    readonly dialog: MatDialog,
    readonly queryingService: ERPQueryingService,
    private readonly $reportOpener: ReportOpenerService
  ) {}

  onShowReceivingReport(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, this.loadingReportEnum.RECEIVING)
      .pipe(
        map(data => ({
          customerId: data.customerId,
          POId: data.POId,
          InboundDeliveryId: data.InboundDeliveryId,
          SOId: data.SOId,
          MRId: data.MRId,
          timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes()
        }))
      )
      .subscribe(data => {
        this.$reportOpener.open(`gateway/api/v1/receivingForCustomer/file?params=${JSON.stringify(data)}`);
      });
  }

  onShowShippingReport(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, this.loadingReportEnum.SHIPPING)
      .pipe(
        map(data => ({
          customerId: data.customerId,
          POId: data.POId,
          InboundDeliveryId: data.InboundDeliveryId,
          SOId: data.SOId,
          MRId: data.MRId,
          timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes()
        }))
      )
      .subscribe(data => {
        this.$reportOpener.open(`gateway/api/v1/shippingForCustomer/file?params=${JSON.stringify(data)}`);
      });
  }

  onShowShippingPackageReport(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, this.loadingReportEnum.SHIPPING_PACKAGE)
      .pipe(
        map(data => ({
          customerId: data.customerId,
          minDateIn: data.minDateIn,
          maxDateIn: data.maxDateIn,
          customerReleaseNumber: data.customerReleaseNumber,
          materialId: data.materialId,
          destination: data.destination,
          timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes()
        }))
      )
      .subscribe(data => {
        this.$reportOpener.open(`gateway/api/v1/shippingAllOnOne/file?params=${JSON.stringify(data)}`);
      });
  }

  onShowStorageReport(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, this.loadingReportEnum.STORAGE_REPORT)
      .pipe(
        map(data => ({
          ownerId: data.customerId,
          gracePeriod: data.gracePeriod,
          isFtz: false,
          pricePerTon: data.pricePerTon,
          timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes()
        }))
      )
      .subscribe(data => {
        this.$reportOpener.open(`gateway/api/v1/storageCharges/file?params=${JSON.stringify(data)}`);
      });
  }

  onShowStorageReportFTZ(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, this.loadingReportEnum.STORAGE_REPORT_FTZ)
      .pipe(
        map(data => ({
          ownerId: data.customerId,
          gracePeriod: data.gracePeriod,
          isFtz: true,
          pricePerTon: data.pricePerTon,
          timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes()
        }))
      )
      .subscribe(data => {
        this.$reportOpener.open(`gateway/api/v1/storageCharges/file?params=${JSON.stringify(data)}`);
      });
  }

  onShowOnHandReport(componentType: ComponentType<unknown> | TemplateRef<unknown>) {
    this.getLoadingDialogClosed$(componentType, 'on-hand').subscribe((data: IOnHandReport) => {
      const updatedData = { ...data, timeZoneOffset: ERPDateUtil.caluclateTimeZoneDiffInMinutes() };
      this.$reportOpener.open(`gateway/api/v1/yardToDate/file?params=${JSON.stringify(updatedData)}`);
    });
  }

  private getLoadingDialogClosed$(
    componentType: ComponentType<unknown> | TemplateRef<unknown>,
    type: LoadingReportType
  ) {
    return this.dialog
      .open(componentType, {
        width: '26.75rem',
        minWidth: '26.75rem',
        data: {
          type
        }
      })
      .afterClosed()
      .pipe(filter(identity));
  }
}
