export enum EMode {
  CREATE = 'create',
  VIEW = 'view',
  EDIT = 'edit'
}

export enum ERPMode {
  CreateNew = 'create-new',
  ViewEdit = 'view/edit',
  CreateFrom = 'create-from',
  Edit = 'edit',
  View = 'view',
  Create = 'create'
}
