import { Injectable } from '@angular/core';
import { IERPFormGroup, IFormConfig } from '../../../../forms/src/lib/modules/form-builder/types';

@Injectable()
export class ERPLabelMapService {
  private readonly exeptions: [string, string][] = [['address', 'Address']];

  createLabelMap(formConfigArr: (IERPFormGroup | IFormConfig)[]): Map<string, string> {
    const labelMap = new Map<string, string>(this.exeptions);

    formConfigArr.forEach(formConfig => {
      const lookupConfig: IFormConfig = (formConfig.children || formConfig) as IFormConfig;
      for (const childName in lookupConfig) {
        if (lookupConfig.hasOwnProperty(childName)) {
          const child = lookupConfig[childName];
          labelMap.set(child.name.toLowerCase(), (child.label ?? '').replace(':', ''));
        }
      }
    });

    return labelMap;
  }
}
