import { ERPApiConfigService } from './api-config.service';

export function ERPApiConfigInitializer(apiConfigService: ERPApiConfigService, apiDomain: string, apiUrl: string) {
  return () =>
    new Promise(resolve => {
      apiConfigService['_apiDomain'] = apiDomain;
      apiConfigService['_apiUrl'] = apiUrl;
      resolve(null);
    });
}
