<h1 class="mat-headline-5 dialog-header margin-right-5" mat-dialog-title>
  <ng-container>Device Parameters</ng-container>
</h1>
<section class="dialog-content erp-tablet-module" mat-dialog-content>
  <div [formGroup]="form" class="grid grid-column-gap-c grid-row-gap-p">
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>MRP:</ng-container>
      </erp-label>
      <erp-input formControlName="mrp" [isFocused]="true"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Run Out:</ng-container>
      </erp-label>
      <erp-input formControlName="runOut"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Go No-Go:</ng-container>
      </erp-label>
      <erp-input formControlName="goNoGo"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Caliper:</ng-container>
      </erp-label>
      <erp-input formControlName="caliper"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Protractor:</ng-container>
      </erp-label>
      <erp-input formControlName="protractor"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Ring:</ng-container>
      </erp-label>
      <erp-input formControlName="ring"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Lead:</ng-container>
      </erp-label>
      <erp-input formControlName="lead"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Taper:</ng-container>
      </erp-label>
      <erp-input formControlName="taper"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Height:</ng-container>
      </erp-label>
      <erp-input formControlName="height"></erp-input>
    </div>
    <div class="col-4">
      <erp-label [required]="true">
        <ng-container>Profile:</ng-container>
      </erp-label>
      <erp-input formControlName="profile"></erp-input>
    </div>
  </div>
</section>
<section class="flex justify-content-flex-end erp-app erp-app-tablet" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" (click)="onCancel()" mat-dialog-close>
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button
    [disabled]="!form.valid || data.mode === 'view'"
    medium
    class="dialog-action-confirm"
    color="accent"
    (click)="onSave()"
  >
    <ng-container> Save </ng-container>
  </erp-button>
</section>
