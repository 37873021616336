import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { ERPButtonModule } from '../button';
import { ERPIconModule } from '../icon';
import { ERPPanelModule } from '../panel';
import { ERPTooltipModule } from '../tooltip';

import { ERPAttachmentComponent, ERPAttachmentFileComponent, ERPAttachmentsComponent } from './components';
import { ERPAttachmentsService } from './services';

@NgModule({
  declarations: [ERPAttachmentComponent, ERPAttachmentsComponent, ERPAttachmentFileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    MatDividerModule,
    MatProgressBarModule,
    MatDialogModule,
    ERPIconModule,
    ERPPanelModule,
    ERPButtonModule,
    ERPTooltipModule
  ],
  exports: [ERPAttachmentComponent, ERPAttachmentsComponent, ERPAttachmentFileComponent],
  providers: [ERPAttachmentsService]
})
export class ERPAttachmentModule {}
