<mat-form-field appearance="outline" [@.disabled]="true">
  <input
    autocomplete="off"
    [formControl]="control"
    type="text"
    inputmode="numeric"
    [erpFocus]="isFocused"
    matInput
    #input="matInput"
    [readonly]="readonly"
    [placeholder]="placeholder"
    (keydown.enter)="onEnterKeyDown($event)"
    (keypress)="onKeyPress($event)"
    (focus)="onFocus()"
    (focusout)="onFocusOut()"
    (blur)="onBlur()"
    (paste)="onPaste($event)"
    [matAutocomplete]="auto"
  />

  <span class="suff" matSuffix>
    <ng-content select="[suffix]"></ng-content>
  </span>

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>

  <mat-hint>
    <ng-content></ng-content>
  </mat-hint>

  <mat-error *ngIf="ctrl?.control?.hasError('required')" i18n="@@common.form.errors.required">
    This field is required
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('safeDecimal')" i18n="@@common.form.errors.safe-decimal">
    The number is outside the supported range
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('safeInteger')" i18n="@@common.form.errors.safe-integer">
    The number is outside the supported range
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('numberRange')" i18n="@@common.form.errors.number-range">
    Use numbers within the '{{ control.getError('numberRange')?.minValue }} -
    {{ control.getError('numberRange')?.maxValue }}' range
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('greaterThan')" i18n="@@common.form.errors.greater-than">
    Use value greater than '{{ control.getError('greaterThan')?.minValue }}'
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('lessThan')" i18n="@@common.form.errors.less-than">
    Use value lower than '{{ control.getError('lessThan')?.maxValue }}'
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('greaterThanOrEqual')" i18n="@@common.form.errors.greater-than-or-equal">
    Use value greater or equal to
    {{ control.getError('greaterThanOrEqual')?.minValue }}
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('lessThanOrEqual')" i18n="@@common.form.errors.less-than-or-equal">
    Use value lower or equal to
    {{ control.getError('lessThanOrEqual')?.maxValue }}
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('maxFractionDigits')" i18n="@@common.form.errors.max-fraction-digits">
    Use up to
    {{ control.getError('maxFractionDigits')?.maxDigits }}
    digits after the decimal point
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('diffNumber')" class="tablet-err">
    {{ control.getError('diffNumber') }}
  </mat-error>
  <mat-error *ngIf="ctrl?.control?.hasError('positiveNumber')">
    <ng-container> {{ control.getError('positiveNumber') }} </ng-container>
  </mat-error>
</mat-form-field>
