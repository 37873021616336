<!-- <erp-confirm
  i18n-header="@@common.dialogs.close-line.header"
  header="Close this line?"
  i18n-message="@@common.dialogs.close-line.message"
  message="After closing you will not be able to make any changes to the line."
  i18n-cancel="@@common.dialogs.close-line.buttons.keep-it"
  cancel="No"
  i18n-confirm="@@common.dialogs.close-line.buttons.delete-it"
  confirm="Yes"
  color="warn"
></erp-confirm> -->

<erp-confirm>
  <span cancel></span>
  <span message>
    <p i18n="@@sales.sales-order.document-outdated.confirm-message.line-1">
      Another user has made some changes on the current page.
    </p>
    <p i18n="@@sales.sales-order.document-outdated.confirm-message.line-2">
      Click on the button below to reload the tab if you want to continue modifying.
    </p>
  </span>
</erp-confirm>
