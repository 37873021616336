import { Injectable } from '@angular/core';

export enum UomType {
  Inch = 1,
  Metre = 2,
  Centimetre = 3,
  Millimetre = 4,
  Pound = 6,
  Foot = 7,
  Tonne = 8,
  Hundredweight = 9,
  Kilogram = 10,
  Joint = 14,
  NetTonne = 15,
  Each = 16
}

export class UOMDefinition {
  id: number;
  unit: string;
  symbol: string;
  precision: string;
  class: 'length' | 'weight' | 'countable';
}

@Injectable()
export class ERPUOMDefinitionService {
  readonly EA: UOMDefinition = {
    id: UomType.Each,
    unit: 'Each',
    symbol: 'EA',
    precision: '0',
    class: 'countable'
  } as UOMDefinition;
  readonly JNT: UOMDefinition = {
    id: UomType.Joint,
    unit: 'Joint',
    symbol: 'JNT',
    precision: '0',
    class: 'countable'
  };
  readonly CWT: UOMDefinition = {
    id: UomType.Hundredweight,
    unit: 'Hundredweight',
    symbol: 'CWT',
    precision: '0.000',
    class: 'weight'
  };
  readonly KG: UOMDefinition = {
    id: UomType.Kilogram,
    unit: 'Kilogram',
    symbol: 'KG',
    precision: '0.00',
    class: 'weight'
  };
  readonly NT: UOMDefinition = {
    id: UomType.NetTonne,
    unit: 'Net ton',
    symbol: 'NT',
    precision: '0.000',
    class: 'weight'
  };
  readonly LB: UOMDefinition = {
    id: UomType.Pound,
    unit: 'Pound',
    symbol: 'LB',
    precision: '0.00',
    class: 'weight'
  };
  readonly T: UOMDefinition = {
    id: UomType.Tonne,
    unit: 'Tonne',
    symbol: 'T',
    precision: '0.000',
    class: 'weight'
  };
  readonly CM: UOMDefinition = {
    id: UomType.Centimetre,
    unit: 'Centimetre',
    symbol: 'CM',
    precision: '0.00',
    class: 'length'
  };
  readonly FT: UOMDefinition = {
    id: UomType.Foot,
    unit: 'Foot',
    symbol: 'FT',
    precision: '0.00',
    class: 'length'
  };
  readonly IN: UOMDefinition = {
    id: UomType.Inch,
    unit: 'Inch',
    symbol: 'IN',
    precision: '0.00',
    class: 'length'
  };
  readonly M: UOMDefinition = {
    id: UomType.Metre,
    unit: 'Metre',
    symbol: 'M',
    precision: '0.00',
    class: 'length'
  };
  readonly MM: UOMDefinition = {
    id: UomType.Millimetre,
    unit: 'Millimetres',
    symbol: 'MM',
    precision: '0.00',
    class: 'length'
  };

  getPrecision(unit: string | number) {
    const UOM: UOMDefinition | undefined = Object.values(this).find(value => {
      return value.unit === unit || value.symbol === unit || value.id === unit;
    });

    return UOM?.precision || '0';
  }
}
