import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IAttachment } from '../interface';

@Injectable()
export class ERPAttachmentsService {
  selectedElement: BehaviorSubject<IAttachment | null> = new BehaviorSubject<IAttachment | null>(null);
  onScrollToElement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSetDefaultAttachment: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isFirsInit = true;

  onSelectElement(element: IAttachment | null) {
    this.selectedElement.next(element);
  }

  onSetDefaultAttachment(boolean: boolean = true) {
    this.isSetDefaultAttachment.next(boolean);
  }

  onInitAttachmentsTab(): void {
    if (this.isFirsInit) {
      this.isSetDefaultAttachment.next(true);
      this.isFirsInit = false;
    }
  }
}
