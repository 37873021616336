import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseHttpService, ICollectionResponse } from '../abstracts';
import type {
  ISalesQuoteAllocatedMaterial,
  IStockMaterial,
  IStockMaterialInventory,
  IStockMaterialQuantityChunk,
  IStockMaterialQuantityReference
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPStockService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('stocks');
  }

  getStockMaterials(params: object) {
    return this.get<ICollectionResponse<IStockMaterial>>(params).pipe();
  }

  getAllStockMaterials(params: object) {
    return this.get<ICollectionResponse<IStockMaterial>>(params, 'allMaterials').pipe();
  }

  getAllocatedTransactions(params: object) {
    return this.get<ICollectionResponse<ISalesQuoteAllocatedMaterial>>(params, 'documents');
  }

  getStockMaterialsQuantityReference(params: object) {
    return this.get<ICollectionResponse<IStockMaterialQuantityReference>>(params, 'quantity');
  }

  getStockMaterialsQuantityChunk(params: object) {
    return this.get<ICollectionResponse<IStockMaterialQuantityChunk>>(params, 'chunkQuantity');
  }

  getStockMaterialsInventory(params: object): Observable<ICollectionResponse<IStockMaterialInventory>> {
    return this.get<ICollectionResponse<IStockMaterialInventory>>(params, 'inventory');
  }

  getTotals(params: object): Observable<ICollectionResponse<IStockMaterialInventory>> {
    return this.get<ICollectionResponse<IStockMaterialInventory>>(params, 'inventory/total', {
      headers: {
        'skip-loader': 'true'
      }
    });
  }

  getExportData(params: object): Observable<HttpResponse<Blob>> {
    return this.get(params, 'inventory/document', { responseType: 'blob', observe: 'response' });
  }
}
