<erp-autocomplete
  [formControl]="control"
  icon="action:search"
  [autoSelect]="autoselect"
  [labelFn]="labelFn"
  [displayFn]="displayFn"
  [optionsFn]="optionsFn"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [clearable]="clearable"
  [multiselect]="multiselect"
  [openOnFocus]="openOnFocus"
  [createNew]="createNew"
  [labelProp]="labelProp"
  [isFocused]="isFocused"
  [populateValueByID]="populateValueByID"
  [isFormBuilder]="isFormBuilder"
  [optionsPaging]="optionsPaging"
  [minlength]="minlength"
  [makeInitialRequest]="makeInitialRequest"
  (changed)="onChange($event)"
  (multiSelected)="multiSelected.emit($event)"
>
  <erp-icon
    erpTooltip="Open Advanced Search"
    i18n-erpTooltip="@@common.controls.advanced-search.tooltip-message"
    *ngIf="control?.enabled"
    color="primary"
    class="cursor-pointer"
    icon="action:full-screen-on-filled"
    (click)="onAdvancedSearch($event)"
  ></erp-icon>
</erp-autocomplete>
