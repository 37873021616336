import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import {
  ERPNoAccessPageComponent,
  ERPNotificationListResolver,
  ERPSidenavComponent,
  loadNavigationFailedRoute,
  loadNotFoundRoute
} from '@erp/components';
import { ERPNotificationListComponent } from '@erp/components/notifications/components';
import { ERPPDPRolesResolver } from '@erp/shared';

import { ERPLandingComponent } from './components';
import { pageTitles } from './page-titles';
import { ERPReportComponent } from '@erp/components/reports/report/report.component';

const routes: Routes = [
  {
    path: '',
    component: ERPLandingComponent,
    canActivate: [MsalGuard],
    data: { title: pageTitles.main }
  },
  {
    path: 'tablet',
    data: { title: pageTitles.tablet },
    canActivate: [MsalGuard],
    loadChildren: () => import('@erp/tablet').then(m => m.ERPTabletModule).catch(loadNavigationFailedRoute)
  },
  {
    path: '',
    component: ERPSidenavComponent,
    data: { resourcesId: 'side-menu' },
    canActivate: [MsalGuard],
    resolve: {
      roles: ERPPDPRolesResolver
    },
    children: [
      {
        path: 'inventory',
        data: {
          title: pageTitles.inventoryManagement
        },
        loadChildren: () => import('@erp/inventory').then(m => m.ERPInventoryModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'sales',
        data: { title: pageTitles.sales },
        loadChildren: () => import('@erp/sales').then(m => m.ERPSalesModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'forms',
        data: { title: pageTitles.formBuilder },
        loadChildren: () => import('@erp/forms').then(m => m.ERPFormsModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'procurement',
        data: { title: pageTitles.procurement },
        loadChildren: () =>
          import('@erp/procurement').then(m => m.ERPProcurementModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'production',
        data: { title: pageTitles.production },
        loadChildren: () => import('@erp/production').then(m => m.ERPProductionModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'administration',
        data: { title: pageTitles.administration },
        loadChildren: () =>
          import('@erp/administration').then(m => m.ERPAdministrationModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'logistic-and-warehousing',
        data: { title: pageTitles.logisticWarehousing },
        loadChildren: () => import('@erp/logistic').then(m => m.ERPLogisticModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'planning',
        data: { title: pageTitles.planing },
        loadChildren: () => import('@erp/planning').then(m => m.ERPPlanningModule).catch(loadNavigationFailedRoute)
      },
      {
        path: 'notifications',
        component: ERPNotificationListComponent,
        resolve: {
          list: ERPNotificationListResolver
        },
        data: { title: pageTitles.notifications },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'no-access',
        component: ERPNoAccessPageComponent
      },
      {
        path: 'report/:id',
        component: ERPReportComponent
      }
    ]
  },
  {
    path: '**',
    data: { title: pageTitles.main },
    loadChildren: loadNotFoundRoute
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always'
    })
  ]
})
export class ERPAppRoutingModule {}
