import { Injectable, Injector, inject } from '@angular/core';
import { ADVANCED_SEARCH_COMPOENT, IAdvancedSearchComponent } from './tokens';
import { ComponentType } from '@angular/cdk/portal';

type AllComponents = IAdvancedSearchComponent[];

@Injectable()
export class ERPAdvancedSearchComponentService {
  private componentsMap = new Map<string, ComponentType<unknown>>();

  constructor(private readonly $injector: Injector) {}

  register(name: string, cmp: ComponentType<unknown>) {
    if (this.componentsMap.has(name)) {
      // eslint-disable-next-line
      console.warn(`component with name: ${name} was already registered, so it will be overriden`);
    }
    this.componentsMap.set(name, cmp);
  }

  getComponent(componentName: string): ComponentType<unknown> | null {
    let component: ComponentType<unknown> | null;
    if (this.componentsMap.has(componentName)) {
      component = this.componentsMap.get(componentName) ?? null;
    } else {
      const components: AllComponents = this.$injector.get(ADVANCED_SEARCH_COMPOENT) ?? ([] as AllComponents);
      component = components.find(providedCmp => providedCmp.COMPONENT_NAME === componentName)?.class ?? null;
    }

    return component;
  }
}
