import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

import { IAttachment } from '../../interface';
import { Observable, Subject, filter, map, mergeMap, takeUntil, tap } from 'rxjs';
import { AutoCleanupFeature, Features, IDestroyable, Memoise } from '../../../../../../../shared/src/lib';
import { ERPAttachmentsService } from '../../services';
import { identity, take } from 'lodash';

@Component({
  selector: 'erp-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPAttachmentComponent implements OnInit, OnDestroy {
  readonly destroyed$ = new Subject<void>();
  @Input() readonly errorLoading = false;
  @Input() readonly isLoadingType = false;
  @Input() readonly loadingProgress = 0;
  @Input() readonly attachment: IAttachment;

  @Output() readonly delete = new EventEmitter();
  @Output() readonly download = new EventEmitter<IAttachment>();
  @Output() readonly interruptLoading = new EventEmitter<string>();
  @Output() readonly isSelected = new EventEmitter<void>();
  icon: SafeStyle;
  @Input() readonly underline: boolean;
  @Input() readonly withLabels: boolean;
  @Input() readonly trimSize: number;
  @Input() readonly withSelect: boolean;
  trimmedFileName: string | null;

  @ViewChild('container', { static: true }) container: ElementRef;

  constructor(private readonly $attachmentService: ERPAttachmentsService) {}

  ngOnInit() {
    this.icon = this.getIcon(this.attachment.fileName);
    this.trimmedFileName = this.trimFileName(this.attachment.fileName, this.trimSize);

    if (this.withSelect) {
      this.container.nativeElement.setAttribute('tabindex', '0');
      this.container.nativeElement.setAttribute('style', 'cursor: pointer');
    }

    this.$attachmentService.onScrollToElement
      .pipe(
        mergeMap(() => this.isElementSelected),
        filter(identity),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        const offset = -70;
        this.container.nativeElement.focus();
        const y = this.container.nativeElement.getBoundingClientRect().top + window.pageYOffset + offset;
        this.container.nativeElement.scrollTo({ top: y, behavior: 'smooth' });
      });
  }

  getExtension(fileName: string) {
    const [, extension] = fileName.split('.');

    return extension;
  }

  private getIcon(fileName: string) {
    const extension = this.getExtension(fileName)?.toLowerCase();
    let icon: string;

    switch (extension) {
      case 'pdf':
        icon = 'pdf';
        break;
      case 'zip':
      case 'tar':
      case 'rar':
      case '7z':
        icon = 'archive';
        break;
      case 'image':
      case 'jpg':
      case 'jpeg':
      case 'png':
        icon = 'image';
        break;
      case 'doc':
      case 'docx':
      case 'rtf':
      case 'odf':
        icon = 'document';
        break;
      case 'xls':
      case 'xlsx':
      case 'csv':
        icon = 'excel';
        break;
      case 'ppt':
      case 'pot':
      case 'pps':
      case 'pptx':
      case 'ppsx':
      case 'sldx':
        icon = 'powerpoint';
        break;
      default:
        icon = 'generic';
    }

    return `file-type:${icon}`;
  }

  @Memoise()
  get selectedElement(): Observable<IAttachment | null> {
    return this.$attachmentService.selectedElement;
  }

  @Memoise()
  get isElementSelected(): Observable<boolean> {
    return this.selectedElement.pipe(
      filter((element: IAttachment | null) => !!this.withSelect),
      map((element: IAttachment | null) => element?.id === this.attachment?.id),
      tap((isSelected: boolean) => {
        if (isSelected) {
          this.isSelected.emit();
        }
      })
    );
  }

  onSelectElement(event: Event) {
    const isAttachmentSelected = this.$attachmentService.selectedElement.value?.id === this.attachment.id;

    if (!this.withSelect || isAttachmentSelected) {
      return;
    }

    event.stopPropagation();
    this.$attachmentService.onSelectElement(this.attachment);
  }

  onInterrupt(event: Event) {
    event.stopPropagation();
    this.interruptLoading.emit(this.attachment.uniqueName);
  }

  onRemove(event: Event) {
    event.stopPropagation();
    this.delete.emit();
  }

  onDownload(event: Event) {
    event.stopPropagation();
    this.download.emit();
  }

  private trimFileName(fileName: string, trimSize: number): string | null {
    const splitIndex = fileName.lastIndexOf('.');
    const name = fileName.substring(0, splitIndex);
    if (name?.length > trimSize) {
      const extension = fileName?.substring(splitIndex, fileName.length);

      return `${name.substring(0, trimSize)}..${extension}`;
    }

    return null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
