import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ERPPDPRolesService, ERPReportsService, IPDPRoles } from '@erp/shared';

import { ERPLogisticLoadingReportComponent } from '../logistic-loading-report/logistic-loading-report.component';
import { ERPOnHandReportComponent } from '../on-hand-report';
import { ERPLogisticReportsService } from './logistic-reports.service';

@Component({
  selector: 'erp-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ERPLogisticReportsService]
})
export class ERPSidenavListComponent implements OnInit {
  @Input() roles: IPDPRoles;

  readonly prefix = 'side-menu';
  allReports: [{ id: string; name: string }];

  constructor(
    private readonly logisticReportService: ERPLogisticReportsService,
    readonly pdpService: ERPPDPRolesService,
    readonly reportsService: ERPReportsService
  ) {}

  ngOnInit(): void {
    this.getReportsList();
  }

  getReportsList() {
    this.reportsService.getReportsList().subscribe(data => {
      this.allReports = data;
    });
  }

  onShippingReport() {
    this.logisticReportService.onShowShippingReport(ERPLogisticLoadingReportComponent);
  }

  onReceivingReport() {
    this.logisticReportService.onShowReceivingReport(ERPLogisticLoadingReportComponent);
  }

  onOnHandReport() {
    this.logisticReportService.onShowOnHandReport(ERPOnHandReportComponent);
  }

  onShippingPackageReport() {
    this.logisticReportService.onShowShippingPackageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReport() {
    this.logisticReportService.onShowStorageReport(ERPLogisticLoadingReportComponent);
  }

  onStorageReportFTZ() {
    this.logisticReportService.onShowStorageReportFTZ(ERPLogisticLoadingReportComponent);
  }
}
