import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

export enum MaterialAllocationPurpose {
  'Finished Good' = 1,
  'Raw' = 2
}

@Injectable()
export class MaterialsSpredService {
  readonly selectedTab = new FormControl(0);

  getMaterialType() {
    switch (this.selectedTab.value) {
      case 0:
        return { id: 1, value: MaterialAllocationPurpose[1] };
      case 1:
      default:
        return { id: 2, value: MaterialAllocationPurpose[2] };
    }
  }
}
