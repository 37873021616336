import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';
import { ISelectOption } from '../../../../components/src/lib';
import { map } from 'rxjs';

// TODO: remove as unused
@Injectable({ providedIn: 'root' })
export class SalesOrderStatusResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.salesOrderStatus;
  }
}
