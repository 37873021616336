<div class="modal-top">
  <erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="undefined">
    <erp-icon icon="action:cross"></erp-icon>
  </erp-button>
</div>

<h1 class="header dialog-header margin-right-5 margin-bottom-5" mat-dialog-title>
  <ng-container i18n="@@procurement.purchase-orders.create-pdf.popup.header">{{ header }}</ng-container>
</h1>

<form [formGroup]="form" class="generate-pdf-form">
  <div class="margin-bottom-3">
    <erp-label [required]="true" class="margin-bottom-1">
      <ng-container i18n="@@procurement.purchase-orders.create-pdf.popup.form.uom"> UOM: </ng-container>
    </erp-label>
    <erp-autocomplete
      icon="action:search"
      [autoSelect]="true"
      [displayFn]="uomDisplayFn"
      [labelFn]="uomLabelFn"
      [valueFn]="uomValueFn"
      [optionsFn]="uomOptionsFn"
      formControlName="uom"
    ></erp-autocomplete>
  </div>
</form>

<section class="flex justify-content-flex-end" mat-dialog-actions>
  <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close (click)="onCancel()">
    <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
  </erp-button>
  <erp-button [disabled]="!form.valid" class="dialog-action-confirm" color="accent" (click)="onCreate()">
    <ng-container i18n="@@common.buttons.create-pdf"> Create PDF </ng-container>
  </erp-button>
</section>
