import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SalesOrderTypeResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.salesOrderType.pipe(
      map(types =>
        types.sort((a, b) => {
          if (b.id === 1) {
            return 1;
          } else if (a.id === 1) {
            return -1;
          } else {
            return a.id - b.id;
          }
        })
      )
    );
  }
}
