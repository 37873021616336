import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ERPConfirmModule } from '../confirm';
import { DocumentOutDatedDialogComponent } from './components/document-outdated-dialog/document-outdated-dialog.component';

@NgModule({
  imports: [CommonModule, ERPConfirmModule],
  declarations: [DocumentOutDatedDialogComponent],
  providers: [],
  exports: [DocumentOutDatedDialogComponent]
})
export class ERPDocumentOutdatedModule {}

export { ERPDocumentState } from './services/document-state.service';
export * from './services/page-form';
