import { EMPTY, Observable } from 'rxjs';
import { filter, map, single, switchMap, takeWhile } from 'rxjs/operators';
import { generate } from 'shortid';

import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import {
  AutoCleanupFeature,
  BaseArrayComponent,
  Features,
  IDestroyable,
  ValidatorFeature,
  ValueAccessorFeature
} from '@erp/shared';

import { ERPConfirmComponent } from '../../../confirm/components/confirm';
import { IConfirmDialogData } from '../../../confirm/interfaces';
import { ERPToasterService } from '../../../toaster/services';
import { IAttachment } from '../../interface';
import { ERPAttachmentsService } from '../../services';

const NOOP_HANDLER = () => EMPTY;
const LOAD_MULTIPLIER = 100;
const MAX_PROGRESS = 99;
const TOO_LARGE_ERROR = 413;
const DEFAULT_TRIM_SIZE = 150;

@Component({
  selector: 'erp-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
@Features([AutoCleanupFeature(), ValueAccessorFeature(), ValidatorFeature()])
export class ERPAttachmentsComponent extends BaseArrayComponent<IAttachment> implements IDestroyable {
  readonly destroyed$: Observable<unknown>;

  readonly form = new UntypedFormArray([]);
  readonly loadings = new Map<string, IAttachment>();
  private interruptName: string | null;

  dragOver: boolean;
  @Input() readonly attachmentUnderline = false;
  @Input() readonly attachmentWithLabels = false;
  @Input() readonly collapsible = false;
  @Input() collapsed = true;
  @Input() readonly trimSize = DEFAULT_TRIM_SIZE;
  @Input() readonly withSelect = false;

  @ContentChild('fileAddOn', { static: false }) attachmentAddOnRef: TemplateRef<any>;
  @ViewChild('input', { static: true })
  readonly fileUpload: ElementRef<HTMLInputElement>;
  @Input()
  readonly uploadFn: (file: FormData, force?: boolean) => Observable<HttpEvent<IAttachment>> = NOOP_HANDLER;
  @Input()
  readonly downloadFn: (fileId: number) => Observable<Blob> = NOOP_HANDLER;
  @Input()
  readonly deleteFn: (fileId: number) => Observable<HttpEvent<IAttachment>> = NOOP_HANDLER;

  readonly modelToViewFormatter = (value: IAttachment[]) => {
    const formArray = (value?.map(x => new UntypedFormControl(x)) ?? []) as unknown as IAttachment[];

    return formArray;
  };

  readonly trackByControl = (_index: number, control: UntypedFormControl) => {
    return control;
  };

  readonly trackByKey = (_index: number, loading: { key: string; value: IAttachment }) => {
    return loading.key;
  };

  constructor(
    readonly changeDetector: ChangeDetectorRef,
    readonly dialog: MatDialog,
    readonly toasterService: ERPToasterService,
    readonly $attachmentService: ERPAttachmentsService
  ) {
    super();
  }

  writeValue(value: IAttachment[]) {
    super.writeValue(value);
    this.changeDetector.detectChanges();
  }

  onSelectFiles() {
    const fileUpload = this.fileUpload.nativeElement;
    const files = Array.from(fileUpload.files ?? []);

    for (const file of files) {
      this.uploadFile(file);
    }
  }

  onUploadClick(event: MouseEvent) {
    event.stopPropagation();
    const { nativeElement } = this.fileUpload;

    nativeElement.value = '';
    nativeElement.click();
  }

  private uploadFile(file: File) {
    const fileName = file.name;
    const index = this.form.controls.findIndex(el => {
      return el.value.fileName === fileName;
    });

    if (index >= 0) {
      this.dialog
        .open(ERPConfirmComponent, {
          data: {
            confirm: $localize`:@@common.attachments.confirm-rewrite:Yes, replace it`,
            message: $localize`:@@common.attachments.rewrite-message:Do you want to replace it?`,
            header: $localize`:@@common.attachments.rewrite-header:A file with this name already exists.`
          } as IConfirmDialogData
        })
        .afterClosed()
        .pipe(
          filter(x => {
            if (x) {
              this.form.removeAt(index);
              this.changeDetector.markForCheck();
            }

            return x;
          })
        )
        .subscribe(() => this.startUploadFile(file, fileName));
    } else {
      this.startUploadFile(file, fileName);
    }
  }

  private startUploadFile(file: File, fileName: string) {
    const uniqueName = generate();
    const formData = new FormData();

    this.loadings.set(uniqueName, {
      fileName,
      uniqueName,
      loadingProgress: 0
    });

    this.changeDetector.markForCheck();

    formData.append('file', file);

    this.uploadFn(formData, true)
      .pipe(
        takeWhile(() => {
          if (uniqueName === this.interruptName) {
            this.interruptName = null;

            return false;
          }

          return true;
        }),
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.handleUploadProgress(event, uniqueName);

              return null;
            case HttpEventType.Response:
              return event.body;
            default:
              return null;
          }
        }),
        filter(data => !!data)
      )
      .subscribe(
        response => {
          this.handleUploadSuccess(uniqueName, response);
        },
        error => {
          const status = error.status;

          const message =
            status === TOO_LARGE_ERROR
              ? $localize`:@@common.error.GEN-80:Looks like the file is too large. Contact the system administrator for assistance.`
              : error.error?.error?.message || 'error.default';

          this.toasterService.error(message);
          this.handleUploadError(uniqueName);
        }
      );
  }

  private handleUploadSuccess(uniqueName: string, response: IAttachment | null) {
    this.loadings.delete(uniqueName);
    this.form.push(new UntypedFormControl(response));
    this.changeDetector.markForCheck();
  }

  private handleUploadError(uniqueName: string) {
    if (this.loadings.has(uniqueName)) {
      const attachment = this.loadings.get(uniqueName) as IAttachment;
      attachment.errorLoading = true;
    }
    this.changeDetector.markForCheck();
  }

  private handleUploadProgress(event: HttpProgressEvent, uniqueName: string) {
    if (this.loadings.has(uniqueName)) {
      const attachment = this.loadings.get(uniqueName) as IAttachment;

      attachment.loadingProgress = Math.min(
        Math.round((event.loaded * LOAD_MULTIPLIER) / (event.total || 1)),
        MAX_PROGRESS
      );
    }
    this.changeDetector.markForCheck();
  }

  onInterrupt(name: string) {
    this.interruptName = name;
    this.loadings.delete(name);
  }

  onDelete(attachment: IAttachment, index: number) {
    if (!attachment.id) {
      return;
    }

    const dialogRef = this.dialog.open(ERPConfirmComponent, {
      data: {
        cancel: $localize`:@@common.attachments.cancel-delete:No`,
        confirm: $localize`:@@common.attachments.confirm-delete:Yes`,
        header: $localize`:@@common.attachments.delete-header:Permanently delete this document?`
      } as IConfirmDialogData
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(el => el),
        switchMap(() => {
          return this.deleteFn(attachment.id as number).pipe(single());
        })
      )
      .subscribe(
        () => {
          this.form.removeAt(index);
          this.changeDetector.markForCheck();

          const isSelectedElementDeleted =
            this.withSelect && this.$attachmentService.selectedElement.value?.id === attachment.id;
          if (isSelectedElementDeleted) {
            this.$attachmentService.onSetDefaultAttachment();
          }
        },
        error => {
          const message = error.error?.error?.message || 'error.default';
          this.toasterService.error(message);
        }
      );
  }

  onDownload(attachment: IAttachment) {
    if (!attachment.id) {
      return;
    }

    this.downloadFn(attachment.id)
      .pipe(single())
      .subscribe(
        response => {
          const anchor = document.createElement('a');

          anchor.href = URL.createObjectURL(response);
          anchor.download = attachment.fileName;
          anchor.click();
        },
        error => {
          const message = error.error?.error?.message || 'error.default';
          this.toasterService.error(message);
        }
      );
  }

  onDrop(event: DragEvent) {
    const { dataTransfer } = event;
    const files = Array.from(dataTransfer?.files ?? []);

    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;

    for (const file of files) {
      this.uploadFile(file);
    }
  }

  onDragEnter(event: DragEvent) {
    const { dataTransfer } = event;
    const items = Array.from(dataTransfer?.items ?? []);
    const files = items.filter(x => x.kind === 'file');

    if (files.length) {
      event.preventDefault();
      event.stopPropagation();
      this.dragOver = true;
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
  }
}
