import { NgModule } from '@angular/core';
import { EmptyListComponent } from './empty-list.component';
import { ERPIconModule } from '../icon';
import { CommonModule } from '@angular/common';
import { ERPButtonModule } from '../button';

@NgModule({
  imports: [ERPIconModule, ERPButtonModule, CommonModule],
  declarations: [EmptyListComponent],
  exports: [EmptyListComponent]
})
export class ERPEmptyListModule {}
