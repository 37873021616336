import { Injectable, InjectionToken } from '@angular/core';
import { IERPFormGroup, IFormConfig } from '@erp/forms';

export interface IPropertyLabelGetter {
  get: (propName: string) => string;
}

export interface IPropertyLabelSetter {
  set: (propName: string, label: string) => void;
}

export type PropertiesMap = IPropertyLabelGetter & IPropertyLabelSetter;

export const ERP_PROP_LABELS = new InjectionToken('@erp/panoptic/prop-labels-provider');

@Injectable()
export class BaseProplabelProvider implements IPropertyLabelGetter, IPropertyLabelSetter {
  private readonly map: Map<string, any>;
  private readonly exeptions: [propName: string, label: string][] = [['address', 'Address']];

  constructor() {
    this.map = new Map<string, any>(this.exeptions);
  }

  createLabelMap(formConfigArr: (IERPFormGroup | IFormConfig)[]) {
    formConfigArr.forEach(formConfig => {
      const lookupConfig: IFormConfig = (formConfig.children || formConfig) as IFormConfig;
      for (const childName in lookupConfig) {
        if (lookupConfig.hasOwnProperty(childName)) {
          const child = lookupConfig[childName];
          this.map.set(child.name.toLowerCase(), (child.label ?? '').replace(':', ''));
        }
      }
    });
  }

  get(propName: string) {
    return this.map.get(propName);
  }

  set(propName: string, label: string) {
    this.map.set(propName, label);
  }

  bulkSet(list: [[propName: string, label: string]]) {
    list.forEach(([prop, label]) => {
      this.set(prop, label);
    });
  }
}
