/* eslint-disable */
import { ViewEncapsulation, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environment';

import { ERPAppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(ERPAppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated
  })
  .catch(err => console.error(err));
