import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ERPLabelModule } from '../../label';
import { ERPRadioModule } from '../../radio';
import { ERPButtonModule } from '../../button';
import { ERPDatePickerModule } from '../../date-picker';
import { ERPDateUtil } from '@erp/shared';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ERPIconModule } from '../../icon';

export interface IPrintPDFData {
  [key: string]: any;
}

@Component({
  standalone: true,
  template: `
    <div class="modal-top">
      <erp-button color="primary" variant="icon" class="dialog-close" [mat-dialog-close]="undefined">
        <erp-icon icon="action:cross"></erp-icon>
      </erp-button>
    </div>

    <h1 class="header dialog-header margin-right-5 margin-bottom-5" mat-dialog-title>
      <ng-container i18n="@@sales.material-release.create-pdf.popup.header">{{ header }}</ng-container>
    </h1>

    <form [formGroup]="params" class="generate-pdf-form">
      <div class="margin-bottom-3">
        <erp-label [required]="true" class="margin-bottom-1">
          <ng-container i18n="@@sales.material-release.create-pdf.popup.form.for"> PDF for: </ng-container>
        </erp-label>
        <erp-radio [options]="variantOptions" formControlName="reportVariant"></erp-radio>
      </div>

      <div class="margin-bottom-3">
        <erp-label [required]="true" class="margin-bottom-1">
          <ng-container i18n="@@sales.material-release.create-pdf.popup.form.document-date"
            >Document Date:
          </ng-container>
        </erp-label>
        <erp-date-picker formControlName="releaseDate"></erp-date-picker>
      </div>
    </form>

    <section class="flex justify-content-flex-end" mat-dialog-actions>
      <erp-button color="primary" class="margin-right-4" variant="link" mat-dialog-close (click)="onCancel()">
        <ng-container i18n="@@common.buttons.cancel"> Cancel </ng-container>
      </erp-button>
      <erp-button [disabled]="!params.valid" class="dialog-action-confirm" color="accent" (click)="onCreate()">
        <ng-container i18n="@@common.buttons.create"> Create </ng-container>
      </erp-button>
    </section>
  `,
  styleUrls: ['./mr-print-pdf.component.scss'],
  imports: [ERPLabelModule, ERPRadioModule, ERPButtonModule, ERPDatePickerModule, ERPIconModule, MatDialogModule]
})
export class ERPMRPrintPDFComponent {
  header: string | null;
  cancel: string | null;
  confirm: string | null;

  params = new FormGroup({
    materialReleaseId: new FormControl(null),
    timeZoneOffset: new FormControl(ERPDateUtil.caluclateTimeZoneDiffInMinutes()),
    reportVariant: new FormControl(1, [Validators.required]),
    releaseDate: new FormControl(new Date(), [Validators.required])
  });

  variantOptions = [
    { id: 1, value: 'Customer' },
    { id: 2, value: 'Facility' }
  ];

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly data: IPrintPDFData,
    private readonly $dialogRef: MatDialogRef<any>
  ) {
    this.cancel = this.data.cancel;
    this.confirm = this.data.confirm;
    this.header = this.data.header;

    this.params.patchValue({ materialReleaseId: this.data.materialReleaseId });
  }

  onCancel() {
    this.$dialogRef.close(undefined);
  }

  onCreate() {
    if (this.params.invalid) return;

    this.$dialogRef.close({
      ...this.params.value
    });
  }
}
