import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityLogs, StyledRevisionRecord } from '../types';

export interface IActivityProvider {
  logs$: Observable<ActivityLogs>;
}

@Injectable()
export abstract class BaseActivityLogProvider implements IActivityProvider {
  abstract logs$: Observable<ActivityLogs>;

  protected groupByDay(revisions: StyledRevisionRecord[]): ActivityLogs {
    const groups: ActivityLogs = [];
    let currentDay: string | null = null;
    let currentGroup: StyledRevisionRecord[] = [];

    revisions.forEach(revision => {
      const date = new Date(revision.dateTime);
      const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      if (key !== currentDay) {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentDay = key;
        currentGroup = [];
      }

      currentGroup.push(revision);
    });

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  }
}

export const ACTIVITY_LOG = new InjectionToken('@erp/panoptic/document-activity-log');
