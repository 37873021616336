import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { ERPLabelModule } from '../../label';
import { ERPRadioModule } from '../../radio';
import { ERPButtonModule } from '../../button';
import { ERPDatePickerModule } from '../../date-picker';
import { ERPDateUtil } from '@erp/shared';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ERPIconModule } from '../../icon';
import { ERPAutocompleteModule } from '../../autocomplete/autocomplete.module';

export interface IPrintPDFData {
  [key: string]: any;
}

@Component({
  standalone: true,
  templateUrl: './wo-print-pdf.component.html',
  styleUrls: ['./wo-print-pdf.component.scss'],
  imports: [
    ERPLabelModule,
    ERPRadioModule,
    ERPButtonModule,
    ERPDatePickerModule,
    ERPIconModule,
    MatDialogModule,
    ERPAutocompleteModule
  ]
})
export class ERPWOPrintPDFComponent {
  header: string | null;
  cancel: string | null;
  confirm: string | null;

  form = new FormGroup({
    timeZoneOffset: new FormControl(ERPDateUtil.caluclateTimeZoneDiffInMinutes()),
    orderDate: new FormControl(null, [Validators.required])
  });

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly data: IPrintPDFData,
    private readonly $dialogRef: MatDialogRef<any>
  ) {
    this.cancel = this.data.cancel;
    this.confirm = this.data.confirm;
    this.header = this.data.header;
  }

  onCancel() {
    this.$dialogRef.close(undefined);
  }

  onCreate() {
    if (this.form.invalid) return;

    this.$dialogRef.close({
      ...this.form.value
    });
  }
}
