import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPurchaseRequisitionAttachments } from '../../interfaces';
import { ISalesOrderReleaseLine } from '../../../../../sales/src/lib/modules/sales-orders/interfaces/sales-order.interface';
import { BaseHttpService, ICollectionResponse } from '../../abstracts';

import {
  IMaterialReleaseItem,
  IMaterialReleaseItemResponse,
  IMaterialReleaseList,
  IMaterialReleasePreviewLine
} from '../../interfaces';

import { ERPMaterialReleaseFactory } from './material-releaese.factory';

@Injectable({
  providedIn: 'root'
})
export class ERPMaterialReleaseService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    readonly materialReleaseFactory: ERPMaterialReleaseFactory
  ) {
    super('releases');
  }

  getList(params: object): Observable<ICollectionResponse<IMaterialReleaseList>> {
    return this.get<ICollectionResponse<IMaterialReleaseList>>(params);
  }

  getBySOPreview(soId: number, params?: object): Observable<ISalesOrderReleaseLine[]> {
    return this.get<ISalesOrderReleaseLine[]>(params, `preview/${soId}`);
  }

  getNames(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'names');
  }

  getById(id: number): Observable<IMaterialReleaseItem> {
    return this.get<IMaterialReleaseItemResponse>({}, `${id}`).pipe(
      map(i => this.materialReleaseFactory.fromResponse(i))
    );
  }

  getReleaseNumbers(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'releaseNumbers');
  }

  getReferenceNumbers(searchString: string): Observable<string[]> {
    return this.get<string[]>({ searchString }, 'referenceNumbers');
  }

  deleteRelease(id: number) {
    return this.delete(undefined, id);
  }

  create(data: IMaterialReleaseItem) {
    const req = this.materialReleaseFactory.toRequest(data);

    return this.post<IMaterialReleaseItemResponse>(req, {}, ``).pipe(
      map(i => this.materialReleaseFactory.fromResponse(i))
    );
  }

  save(data: IMaterialReleaseItem) {
    const req = this.materialReleaseFactory.toRequest(data);

    return this.put<IMaterialReleaseItemResponse>(req, {}, `${data.id}`).pipe(
      map(i => this.materialReleaseFactory.fromResponse(i))
    );
  }

  uploadReleaseAttachment(file: FormData, releaseId: number) {
    return this.post<FormData>(file, { force: true }, `${releaseId}/attachments`, {
      reportProgress: true,
      observe: 'events'
    });
  }

  downloadReleaseAttachment(attachmentId: string, releaseId: number): Observable<Blob> {
    return this.get({}, `${releaseId}/attachments/${attachmentId}`, { responseType: 'blob' });
  }

  deleteReleaseAttachment(attachmentId: string, releaseId: number) {
    return this.delete({}, `${releaseId}/attachments/${attachmentId}`);
  }

  getReleaseAttachments(releaseId: number) {
    return this.get<IPurchaseRequisitionAttachments>({}, `${releaseId}/attachments`);
  }

  getPreview(
    materialReleasePreviews: IMaterialReleasePreviewLine[],
    query = {},
    materialReleaseId?: number
  ): Observable<IMaterialReleaseItem> {
    return this.get<IMaterialReleaseItemResponse>(
      {
        materialReleasePreviews: JSON.stringify(materialReleasePreviews),
        query: JSON.stringify(query),
        materialReleaseId
      },
      `preview`
    ).pipe(map(i => this.materialReleaseFactory.fromResponse(i)));
  }
}
