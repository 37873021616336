import { ChangeDetectionStrategy, Component, Inject, Input, Optional } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { IConfirmDialogData } from '../../interfaces';

@Component({
  selector: 'erp-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ERPConfirmComponent {
  @Input() readonly allowClose: boolean = true;
  @Input() readonly allowCancel: boolean = true;
  @Input() readonly color: ThemePalette = 'accent';
  @Input() readonly header: string;
  @Input() readonly message: string;
  @Input() readonly confirm: string;
  @Input() readonly cancel: string;
  @Input() readonly isShowCancel: boolean = true;
  @Input() readonly cancelAction: boolean | undefined = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    readonly data: IConfirmDialogData
  ) {
    this.allowClose = data?.allowClose ?? this.allowClose;
    this.allowCancel = data?.allowCancel ?? this.allowCancel;
    this.color = data?.color ?? this.color;
    this.header = data?.header ?? this.header;
    this.message = data?.message ?? this.message;
    this.confirm = data?.confirm ?? this.confirm;
    this.cancel = data?.cancel ?? this.cancel;
    this.isShowCancel = data?.isShowCancel ?? this.isShowCancel;
  }
}
