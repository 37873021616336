import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

export interface IAdvancedSearchComponent {
  COMPONENT_NAME: string;
  class: ComponentType<unknown>;
}

export const ADVANCED_SEARCH_COMPOENT = new InjectionToken('@erp/advanced-search-component', {
  providedIn: 'root',
  factory: () => null
});
