import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { BaseHttpService } from '../abstracts';
import { IInventorySpecialty, IInventoryUom, ISalesOrderStatuses } from '../interfaces';
import { IdValue } from '../types';
import { GoodsIssueStatus } from './goods-issue/enums';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends BaseHttpService {
  constructor(readonly httpClient: HttpClient) {
    super('dictionaries');
  }

  get inventoryTypes() {
    return this.get<ISelectOption[]>({}, 'inventoryTypes');
  }

  get materialTypes() {
    return this.get<ISelectOption[]>({}, 'materialTypes');
  }

  get protectorTypes() {
    return this.get<ISelectOption[]>({}, 'protectorTypes');
  }

  get grades() {
    return this.get<ISelectOption[]>({}, 'grades');
  }

  get gradeCategories() {
    return this.get<ISelectOption[]>({}, 'gradeCategories');
  }

  get sizes() {
    return this.get<ISelectOption[]>({}, 'sizes').pipe(
      map(elements =>
        elements.map(element => ({
          ...element,
          value: `${Number(element?.value)?.toFixed(3)}`
        }))
      )
    );
  }

  get walls() {
    return this.get<ISelectOption[]>({}, 'walls').pipe(
      map(elements =>
        elements.map(element => ({
          ...element,
          value: `${Number(element?.value)?.toFixed(3)}`
        }))
      )
    );
  }

  get designs() {
    return this.get<ISelectOption[]>({}, 'designs');
  }

  get connections() {
    return this.get<ISelectOption[]>({}, 'connections');
  }

  get connectionsCategories() {
    return this.get<ISelectOption[]>({}, 'connectionCategories');
  }

  get conditions() {
    return this.get<ISelectOption[]>({}, 'conditions');
  }

  get threads() {
    return this.get<ISelectOption[]>({}, 'threads');
  }

  get compounds() {
    return this.get<ISelectOption[]>({}, 'compounds');
  }

  get ranges() {
    return this.get<ISelectOption[]>({}, 'ranges');
  }

  get inventoryUoms() {
    return this.get<IInventoryUom[]>({}, 'unitsofmeasure');
  }

  get countries() {
    return this.get<ISelectOption[]>({}, 'countries');
  }

  get documentTypes() {
    return this.get<ISelectOption[]>({}, 'adjustmentTypes');
  }

  get inventoryClassifications() {
    return this.get<ISelectOption[]>({}, 'inventoryClassifications');
  }

  get salutations() {
    return this.get<ISelectOption[]>({}, 'salutation');
  }

  get vendorTypes() {
    return this.get<ISelectOption[]>({}, 'vendorTypes');
  }

  get purchaseTypes() {
    return this.get<ISelectOption[]>({}, 'purchaseTypes');
  }

  get purchaseOrderTypes() {
    // return of([
    //   { id: 1, value: 'Material' },
    //   { id: 2, value: 'Service' },
    //   { id: 3, value: 'Work Order' },
    //   { id: 4, value: 'DC' }
    // ]);
    return this.get<ISelectOption[]>({}, 'orderTypes');
  }

  get incoTerms() {
    return this.get<ISelectOption[]>({}, 'incoTerms');
  }

  get netTerms() {
    return this.get<ISelectOption[]>({}, 'netTerm');
  }

  get claimDays() {
    return this.get<ISelectOption[]>({}, 'quantityClaimsDays');
  }

  get claimMonths() {
    return this.get<ISelectOption[]>({}, 'quantityClaimsMonth');
  }

  get endTypes() {
    return this.get<ISelectOption[]>({}, 'endTypeCategories');
  }

  get reviewStatuses() {
    return this.get<ISelectOption[]>({}, 'reviewStatuses');
  }

  get specialties() {
    return this.get<IInventorySpecialty[]>({}, 'specialties');
  }

  get salesQuoteType() {
    return this.get<ISelectOption[]>({}, 'salesQuotaType');
  }

  get salesQuoteStatus() {
    return this.get<ISelectOption[]>({}, 'salesQuotaStatus');
  }

  get salesQuoteReason() {
    return this.get<ISelectOption[]>({}, 'salesQuotaReason');
  }

  get salesQuoteLineType() {
    return this.get<ISelectOption[]>({}, 'salesQuotaLineType');
  }

  get salesOrderType() {
    return this.get<ISelectOption[]>({}, 'salesOrderType');
  }

  get salesOrderStatus() {
    return this.get<ISalesOrderStatuses[]>({}, 'salesOrderStatus').pipe(
      map(statuses => {
        return statuses.map(status => ({
          ...status,
          dataInfluence: status.dataInfluence.map(dataInfluenceItem => ({
            ...dataInfluenceItem,
            properties: (dataInfluenceItem.properties as string).split(';')
          }))
        }));
      })
    );
  }

  get poStatuses() {
    return this.get<ISelectOption[]>({}, 'orderStatuses');
  }

  get poLineStatuses() {
    return this.get<ISelectOption[]>({}, 'orderLineStatuses');
  }

  get requisitionType() {
    return this.get<ISelectOption[]>({}, 'requisitionTypes');
  }

  get supplierType() {
    // return this.get<ISelectOption[]>({}, 'supplierTypes');

    return of([
      { id: 1, value: 'Vendor' },
      { id: 2, value: 'Customer' }
    ]);
  }

  get adjustmentStatuses() {
    return this.get<ISelectOption[]>({}, 'adjustmentStatuses');
  }

  get movementStatuses() {
    return this.get<ISelectOption[]>({}, 'documentStatuses');
  }

  get poDeliveryStatuses() {
    return this.get<ISelectOption[]>({}, 'deliveryLineStatuses');
  }

  get poStandarts() {
    return this.get<ISelectOption[]>({}, 'orderStandards');
  }

  get adjustmentConfirmStatuses() {
    // return this.get<ISelectOption[]>({}, 'adjustmentStatuses');

    return of([
      { id: 1, value: 'Passed' },
      { id: 2, value: 'Failed' },
      { id: 3, value: 'Processing' }
    ]);
  }

  get prStatuses() {
    return this.get<ISelectOption[]>({}, 'requisitionStatuses');
  }

  get processingOrderStatuses() {
    return this.get<ISelectOption[]>({}, 'processingOrderStatuses');
  }

  get processingOrderStandards() {
    return this.get<ISelectOption[]>({}, 'processingOrderStandarts');
  }

  get salesOrStock() {
    return this.get<ISelectOption[]>({}, 'processingOrderType');
  }

  get goodsIssueStatuses(): Observable<IdValue[]> {
    return of([
      { id: GoodsIssueStatus.Open, value: 'Open' },
      { id: GoodsIssueStatus.Confirmed, value: 'Confirmed' },
      { id: GoodsIssueStatus.Processing, value: 'Processing' }
    ]);
  }

  get serviceTypes() {
    return this.get<ISelectOption[]>({}, 'serviceTypes');
  }

  get charpyTypes() {
    return this.get<ISelectOption[]>({}, 'charpies');
  }

  get coatTypes() {
    return this.get<ISelectOption[]>({}, 'coats');
  }

  get heatTreatProcesses() {
    return this.get<ISelectOption[]>({}, 'processes');
  }

  get transportationCompaniesTypes() {
    return this.get<ISelectOption[]>({}, 'companyTypes');
  }

  get driftTypes() {
    return this.get<ISelectOption[]>({}, 'driftTypes');
  }

  get inspectionLevels() {
    return this.get<ISelectOption[]>({}, 'inspectionOptions');
  }

  get orderInspectionRequirements() {
    return this.get<ISelectOption[]>({}, 'orderInspectionRequirements');
  }

  get salesOrderReleaseStatuses() {
    return this.get<ISelectOption[]>({}, 'materialReleaseStatus');
  }

  get salesOrderReleaseLineStatuses() {
    return this.get<ISelectOption[]>({}, 'materialReleaseLineStatus');
  }

  get supplementaryRequirements() {
    return this.get<ISelectOption[]>({}, 'SPLR1');
  }

  get supplementaryRequirements2() {
    return this.get<ISelectOption[]>({}, 'SPLR2');
  }

  get psl() {
    return this.get<ISelectOption[]>({}, 'PSL');
  }

  get drifts() {
    return this.get<ISelectOption[]>({}, 'drifts');
  }

  get loadingAuthStatuses() {
    return this.get<ISelectOption[]>({}, 'loadingAuthStatuses');
  }

  get movementTabletStatuses(): Observable<IdValue[]> {
    return of([
      { id: GoodsIssueStatus.Open, value: 'Open' },
      { id: GoodsIssueStatus.Confirmed, value: 'Confirmed' },
      { id: GoodsIssueStatus.Processing, value: 'Processing' }
    ]);
  }

  get colors() {
    return this.get<ISelectOption[]>({}, 'colors');
  }

  get charpyTestSizes() {
    return this.get<ISelectOption[]>({}, 'charpyTestSizes');
  }

  get charpyTestLocations() {
    return this.get<ISelectOption[]>({}, 'charpyTestLocations');
  }

  get charpyTestOrientations() {
    return this.get<ISelectOption[]>({}, 'charpyTestOrientations');
  }

  get hardnessTestLocations() {
    return this.get<ISelectOption[]>({}, 'hardnessTestLocations');
  }

  get shifts() {
    return this.get<ISelectOption[]>({}, 'shifts');
  }

  get cncs() {
    return this.get<ISelectOption[]>({}, 'cnc');
  }

  get lines() {
    return this.get<{ facilityId: number; id: number; name: string }[]>({}, 'lines');
  }

  get facilities() {
    return this.get<ISelectOption[]>({}, 'facilities');
  }

  getDefects(params: { ncpTypeId?: number; ncpCategoryId?: number } = {}) {
    return this.get<ISelectOption[]>(params, 'defects');
  }

  get allDefects() {
    return this.get<ISelectOption[]>({}, 'defects/all');
  }

  processingServiceStatuses(params: object) {
    return this.get<ISelectOption[]>(params, 'processingServiceStatuses');
  }

  get claimPhases() {
    return this.get<ISelectOption[]>({}, 'salesOrderClaimPhase');
  }

  get class() {
    return this.get<ISelectOption[]>({}, 'salesOrderClass');
  }

  get FOB() {
    return this.get<ISelectOption[]>({}, 'salesOrderFOB');
  }

  get shipVia() {
    return this.get<ISelectOption[]>({}, 'salesOrderShipVia');
  }

  get terms() {
    return this.get<ISelectOption[]>({}, 'salesOrderTerms');
  }

  get freight() {
    return this.get<ISelectOption[]>({}, 'salesOrderFreight');
  }

  get transportationStatuses() {
    return this.get<ISelectOption[]>({}, 'transportationDetailStatus');
  }

  get transportTypes() {
    return this.get<ISelectOption[]>({}, 'transportType');
  }

  get transferOrderStatuses() {
    return this.get<ISelectOption[]>({}, 'transferOrderStatuses');
  }
}
