import { Injectable } from '@angular/core';

import { ISalesOrderRelease, ISalesOrderReleaseResponse } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class ERPSalesOrderReleaseFactory {
  toRequest(release: ISalesOrderRelease): ISalesOrderReleaseResponse {
    return {
      id: release.id,
      rowVersion: release.rowVersion,
      lines: release.lines,
      ...release.mainInfo
    };
  }

  fromResponse(releaseResponse: ISalesOrderReleaseResponse): ISalesOrderRelease {
    const { id, rowVersion, lines } = releaseResponse;

    return {
      id,
      rowVersion,
      lines,
      mainInfo: {
        customerName: releaseResponse.customerName,
        estimatedShippingDate: releaseResponse.estimatedShippingDate,
        materialReleaseNumber: releaseResponse.materialReleaseNumber,
        notes: releaseResponse.notes,
        reference: releaseResponse.reference,
        releaseDate: releaseResponse.releaseDate,
        salesOrderNumber: releaseResponse.salesOrderNumber,
        materialReleaseStatusId: releaseResponse.materialReleaseStatusId,
        salesOrderId: releaseResponse.salesOrderId,
        releasedByUserName: releaseResponse.releasedByUserName,
        releasedByUserId: releaseResponse.releasedByUserId,
        customerId: releaseResponse.customerId
      }
    };
  }
}
