import { Injectable } from '@angular/core';

import { DictionaryService } from '../services';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SalesOrderClaimPhasesResolver {
  constructor(readonly dictionaryService: DictionaryService) {}

  resolve() {
    return this.dictionaryService.claimPhases.pipe(map(phases => phases.sort((a, b) => a.id - b.id)));
  }
}
