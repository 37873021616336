export enum MaterialType {
  Tubing = 1,
  Casing = 2,
  Couplings = 3,
  LinePipe = 4,
  DrillPipe = 5,
  Markers = 7,
  PupJoints = 8,
  Protectors = 9,
  Services = 10,
  Other = 11,
  Consumables = 12,
  MaintenancePart = 13,
  CrossOver = 15,
  CouplingStock = 16,
  OperationPart = 17
}
