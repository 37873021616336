import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ISelectOption } from '@erp/components';

import { GoodsIssueStatus } from './enums';
import {
  IGoodIssueConfirmPatch,
  IGoodsIssueFormItem,
  IGoodsIssueItem,
  IGoodsIssueLine,
  IGoodsIssueListResponse,
  MaterialReleaseHistory
} from './interfaces';
import { ReadyForGoodsIssueResponse } from '../../interfaces';

import { BaseHttpService, ICollectionResponse } from '../../abstracts/http';
import { IPatchRequest } from '../../interfaces/patch-request.interface';
import { ERPGoodIssueFactory } from './goods-issue.factory';
import { TableQueryBuilder } from '../../builders';

@Injectable({
  providedIn: 'root'
})
export class ERPProductionGoodsIssueService extends BaseHttpService {
  constructor(
    readonly httpClient: HttpClient,
    private readonly goodIssueFactory: ERPGoodIssueFactory
  ) {
    super('goodsissues');
  }

  getGoodsIssueBy(id: number): Observable<IGoodsIssueFormItem> {
    return this.get<IGoodsIssueItem>({}, id).pipe(map(goodsIssue => this.goodIssueFactory.fromResponse(goodsIssue)));
  }

  getSalesOrderLinesPreview(id: number | unknown): Observable<IGoodsIssueLine[]> {
    return this.get<ICollectionResponse<IGoodsIssueLine>>({}, `lines/preview?salesOrderIds=${id}`).pipe(
      map((item: ICollectionResponse<IGoodsIssueLine>) => item.data)
    );
  }

  getGoodsIssues(params: object) {
    return this.get<ICollectionResponse<IGoodsIssueListResponse>>(params);
  }

  getGoodsIssuesNames(params: object) {
    return this.getGoodsIssues(params).pipe(map(response => response?.data.map(item => item.documentNumber)));
  }

  deleteGoodsIssue(id: number): Observable<unknown> {
    return this.delete<unknown>({}, id);
  }

  updateGoodsIssue(goodsIssue: IGoodsIssueFormItem): Observable<IGoodsIssueItem> {
    const requestData = this.goodIssueFactory.toRequest(goodsIssue);

    return this.put<IGoodsIssueItem>(requestData, {}, requestData.id);
  }

  createGoodsIssue(goodsIssue: IGoodsIssueFormItem): Observable<IGoodsIssueItem> {
    const requestData = this.goodIssueFactory.toRequest(goodsIssue);

    return this.post<IGoodsIssueItem>(requestData);
  }

  confirmGoodsIssue<T>(id: string): Observable<{ statusId: GoodsIssueStatus }> {
    const body: IPatchRequest<IGoodIssueConfirmPatch>[] = [
      {
        op: 'replace',
        path: 'statusId',
        value: GoodsIssueStatus.Confirmed
      }
    ];

    return this.patch<IPatchRequest<IGoodIssueConfirmPatch>[], { statusId: GoodsIssueStatus }>(body, {}, id);
  }

  getStatuses(params: object): Observable<ISelectOption[]> {
    return this.get<ISelectOption[]>(params, 'InventoryStatuses');
  }

  getGoodsIssueLA(loadingAuthLineIds: number[]): Observable<ICollectionResponse<IGoodsIssueLine>> {
    return this.get<ICollectionResponse<IGoodsIssueLine>>({ loadingAuthLineIds }, 'lines/preview');
  }

  getMaterialReleaseHistory(materialReleaseId: number, paging = { page: 1, perPage: 200 }) {
    const query = new TableQueryBuilder({
      paging,
      sorting: [],
      filtering: [{ by: 'materialReleaseId', match1: materialReleaseId, op: 'eq' }]
    })
      .build()
      .serialize();

    return this.get<ICollectionResponse<MaterialReleaseHistory[]>>({ query }, 'mrHistory');
  }

  confirmGoods(materials: ReadyForGoodsIssueResponse[]) {
    return this.post<ReadyForGoodsIssueResponse[]>(materials, {}, 'confirmed');
  }
}
